import { AxiosError } from 'axios';
import {
  Assignment,
  AssignmentDetailedResults,
  AsyncResult,
  AverageRating,
  BenchmarkGrade,
  Comment,
  CommentData,
  Course,
  Credentials,
  Grade,
  Group,
  Membership,
  Rating,
  RatingData,
  RatingResource,
  Result,
  Review,
  ReviewFeedback,
  CourseUser,
  SubmissionInfo,
  User,
  AssignmentProgress,
  UserRegistration,
  UserUpdateRequest,
  ReviewByItem,
  EvaluationScoreItemCatalog,
  Evaluation,
  EvaluationScoreTimeline,
  EvaluationTarget,
  UserRatingScore,
  RatingScore,
  CommentWithReviewComments,
  DailyTaskEvents,
  EvaluationByItem,
  Purchase,
  SpecialPurchase,
  POST_Purchase,
  POST_SpecialPurchase,
  RubricTemplate,
  POST_RubricTemplate,
  PUT_RubricTemplate,
  AssignmentRubric,
  PUT_AssignmentRubric,
  RubricTarget,
  GroupSyncCategory,
  PageQuery,
  POST_Report,
  Report,
  Notification,
  NotificationMetaData,
  NotificationAction,
  ActivityStats,
  Tag,
  ReviewUsers,
  CommentTemplate,
  RatingTemplate,
  PageQueryParams,
  DeepLink,
  CourseDashboardStats,
  AssignmentDashboardStats,
  RubricDashboardStats,
  UserDashboardStats,
  AdminStatistics,
  Rubric,
  Feedback,
  Content,
  RubricsTargetMap,
  UserWithGroupInfo,
  Reflection,
  ReviewCommentWithName,
  LoginResponse,
  MfaCredentials,
  MfaEnrollment,
  GroupFormationResponse,
  GroupFormationSurvey,
  GroupFormationSurveyPrompt,
  ReviewCommentWithResultData,
  GroupFormationResult,
  GroupWithGroupFormationResults,
  AccessPermission,
  RosterPermission,
  CourseSection,
  CourseSectionUser,
  AssignmentLineItemPair,
  LineItem,
  InstitutionStats,
  ApprovedDomain,
  Contract,
  POST_Contract,
  SeatConsumption,
  CourseRatingAverage,
  InstructorCourse,
  CourseRating,
} from '../types/types';
import { requestGet, requestPost, requestDelete, requestPut } from './functions';

export interface ErrorObj {
  id: string;
  message: string;
  error: AxiosError;
  fatal: boolean;
}

export class ErrorObj {
  id: string;
  message: string;
  error: AxiosError;

  constructor(id: string, message: string, error: AxiosError) {
    this.id = id;
    this.message = message;
    this.error = error;
  }
}

const defaultErrorCb = (err: AxiosError) => {
  console.error(err.message);
};

/**
 * GET /review/{reviewId}
 *
 * @param {number} reviewId ID of review to GET
 * @param {function(Review): *} successCb Callback for success
 * @param {function(object): *} errorCb Callback for error
 */
export const getReview = (reviewId: string, successCb: (arg0: Review) => void, errorCb = defaultErrorCb): void => {
  requestGet('/review/' + reviewId, successCb, errorCb);
};

/**
 * GET /assignment/{assignmentId}/rubric
 *
 * @param {number} assignmentId Assignment ID
 * @param {function(array): *} itemsCb Callback for rubric items
 * @param {function(object): *} errorCb Callback for error
 */
export const getSubmissionRubricItems = (
  assignmentId: string,
  itemsCb: (arg0: Rubric) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet<AssignmentRubric>(`/assignment/${assignmentId}/rubric`, (data) => itemsCb(data.items), errorCb);
};

/**
 * GET /assignment/{assignmentId}/rubric/comments
 *
 * @param assignmentId Assignment ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getRubricComments = (
  assignmentId: string,
  successCb: (arg0: Comment[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet<Comment[]>(`/assignment/${assignmentId}/rubric/comments`, successCb, errorCb);
};

/**
 * GET /assignment/{assignmentId}/rubric/ratings
 *
 * @param assignmentId Assignment ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getRubricRatings = (
  assignmentId: string,
  successCb: (arg0: Rating[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet<Rating[]>(`/assignment/${assignmentId}/rubric/ratings`, successCb, errorCb);
};

/**
 * GET /submission/{submissionId}/info
 *
 * @param submissionId Submission ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getSubmissionInfo = (
  submissionId: string,
  successCb: (data: SubmissionInfo) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/submission/${submissionId}/info`, successCb, errorCb);
};

/**
 * GET /submission/{submissionId}
 *
 * @param {number} submissionId Submission ID
 * @param {function(string): *} submissionCb Callback for submission
 * @param {function(object): *} errorCb Callback for error
 */
export const getSubmission = (
  submissionId: string,
  submissionCb: (arg0: Blob) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet<string>(
    `/submission/${submissionId}`,
    (data, res) =>
      submissionCb(
        new Blob([data], {
          type: res.headers['content-type'],
        }),
      ),
    errorCb,
    'blob',
  );
};

/**
 * GET /review/{reviewId}/feedback
 *
 * @param {number} id ID of feedback to GET
 * @param {function(array): *} reviewFeedbackCb Callback for reviewFeedback
 * @param {function(object): *} errorCb Callback for error
 */
export const getFeedback = (
  reviewId: string,
  reviewFeedbackCb: (arg0: ReviewFeedback[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet<Feedback>(`/review/${reviewId}/feedback`, (data) => reviewFeedbackCb(data.reviewFeedback), errorCb);
};

/**
 * PUT /review/{reviewId}
 *
 * @param {*} reviewId reviewId to post
 * @param {*} reviewComments commentData to post
 * @param {*} reviewRatings ratingData to post
 * @param {*} submissionId submissionId to post
 * @param {*} assignmentId assignmentId to post
 * @param {*} successCb Callback for successful POST
 * @param {*} errorCb Callback for error
 */
export const postReview = (
  reviewId: string,
  reviewComments: CommentData[],
  reviewRatings: RatingData[],
  submissionId: string,
  assignmentId: string,
  successCb: () => void,
  errorCb = defaultErrorCb,
): void => {
  requestPut(
    `/review/${reviewId}`,
    { reviewId, reviewComments, reviewRatings, submissionId, assignmentId },
    successCb,
    errorCb,
  );
};

/**
 * DELETE /review/{reviewId}
 *
 * @param reviewId Review ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const deleteReview = (reviewId: string, successCb: () => void, errorCb = defaultErrorCb): void => {
  requestDelete(`/review/${reviewId}`, successCb, errorCb);
};

/**
 * POST /feedback
 *
 * @param {*} reviewId reviewId to post
 * @param {*} assignmentId assignmentId to post
 * @param {*} submissionId submissionId to post
 * @param {*} reviewFeedback reviewFeedback to post
 * @param {*} successCb Callback for successful post
 * @param {*} errorCb Callback for error
 */
export const postFeedback = (
  reviewId: string,
  assignmentId: string,
  submissionId: string,
  reviewFeedback: ReviewFeedback[],
  successCb: () => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(
    `/review/${reviewId}/feedback`,
    { assignmentId, reviewId, submissionId, reviewFeedback },
    successCb,
    errorCb,
  );
};

/**
 * POST /content
 *
 * @param {*} formData The form data containing the content to post
 * @param {*} contentIdCb Callback for the resulting content ID
 * @param {*} errorCb Callback for error
 */
export const postContent = (
  formData: FormData,
  contentIdCb: (arg0: string) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost<Content>('/content', formData, (data) => contentIdCb(data.contentId), errorCb);
};

/**
 * DELETE /content/{id}
 *
 * @param {*} id
 * @param {*} errorCb
 */
export const deleteContent = (id: string, errorCb = defaultErrorCb): void => {
  requestDelete(`/content/${id}`, () => undefined, errorCb);
};

/**
 * POST /assignment/{assignmentId}/comment
 *
 * @param {*} assignmentId ID of rubric's assignment to add to
 * @param {*} order The order of the new comment prompt
 * @param {*} commentData Comment data
 * @param {*} successCb Callback for the response data
 * @param {*} errorCb Callback for error
 */
export const postCommentNew = (
  assignmentId: string,
  order: number,
  commentData: Comment,
  successCb: (arg0: Comment) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(
    `/assignment/${assignmentId}/comment`,
    {
      ...commentData,
      assignmentId,
      order,
    },
    successCb,
    errorCb,
  );
};

/**
 * POST /assignment/{assignmentId}/rating
 *
 * @param {*} assignmentId ID of rubric's assignment to add to
 * @param {*} order The order of the new rating prompt
 * @param {*} ratingData Rating data
 * @param {*} successCb Callback for the response data
 * @param {*} errorCb Callback for error
 */
export const postRatingNew = (
  assignmentId: string,
  order: number,
  ratingData: Rating,
  successCb: (arg0: Rating) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(
    `/assignment/${assignmentId}/rating`,
    {
      ...ratingData,
      assignmentId,
      order,
    },
    successCb,
    errorCb,
  );
};

/**
 * GET /comment/{commentId}
 *
 * @param commentId Comment ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getComment = (commentId: string, successCb: (arg0: Comment) => void, errorCb = defaultErrorCb): void => {
  requestGet(`/comment/${commentId}`, successCb, errorCb);
};

/**
 * PUT /comment/{commentId}
 *
 * @param {*} commentId The comment ID
 * @param {*} commentData The comment data to post
 * @param {*} successCb Callback for the response data
 * @param {*} errorCb Callback for error
 */
export const updateComment = (
  commentId: string,
  commentData: Comment,
  successCb: (arg0: Comment) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPut(`/comment/${commentId}`, commentData, successCb, errorCb);
};

/**
 * GET /rating/{ratingId}
 *
 * @param ratingId Rating ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getRating = (ratingId: string, successCb: (arg0: Rating) => void, errorCb = defaultErrorCb): void => {
  requestGet(`/rating/${ratingId}`, successCb, errorCb);
};

/**
 * PUT /rating/{ratingId}
 *
 * @param {*} ratingId The rating ID
 * @param {*} ratingData The rating data to post
 * @param {*} successCb Callback for the response data
 * @param {*} errorCb Callback for error
 */
export const updateRating = (
  ratingId: string,
  ratingData: Rating,
  successCb: (arg0: Rating) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPut(`/rating/${ratingId}`, ratingData, successCb, errorCb);
};

/**
 * DELETE /comment/{commentId}
 *
 * @param {*} commentId ID of the comment to delete
 * @param {*} successCb Callback for success
 * @param {*} errorCb Callback for error
 */
export const deleteComment = (commentId: string, successCb: () => void, errorCb = defaultErrorCb): void => {
  requestDelete(`/comment/${commentId}`, successCb, errorCb);
};

/**
 * DELETE /rating/{ratingId}
 *
 * @param {*} ratingId ID of the rating to delete
 * @param {*} successCb Callback for success
 * @param {*} errorCb Callback for error
 */
export const deleteRating = (ratingId: string, successCb: () => void, errorCb = defaultErrorCb): void => {
  requestDelete(`/rating/${ratingId}`, successCb, errorCb);
};

/**
 * GET /result/assignment/{assignmentId}
 *
 * @param assignmentId Assignment ID
 * @param successCb Callback for data
 * @param errorCb Callback for error
 */
export const getMyGradeResults = (
  assignmentId: string,
  successCb: (arg0: Result) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/result/assignment/${assignmentId}`, successCb, errorCb);
};

/**
 * POST /assignment/{assignmentId}/submitter/{submitterId}/submission/file
 *
 * @param {*} assignmentId Assignment ID
 * @param {*} formData The form data containing the submission to post
 * @param {*} submissionIdCb Callback for the resulting submission ID
 * @param {*} successCb Callback for success
 * @param {*} errorCb Callback for error
 */
export const postFileSubmission = (
  assignmentId: string,
  submitterId: string,
  formData: FormData,
  submissionIdCb: (arg0: string) => void,
  successCb: () => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost<SubmissionInfo>(
    `/assignment/${assignmentId}/submitter/${submitterId}/submission/file`,
    formData,
    (data) => {
      submissionIdCb(data.submissionId);
      successCb();
    },
    errorCb,
  );
};

/**
 * POST /assignment/{assignmentId}/submitter/{submitterId}/submission/url
 *
 * @param {*} assignmentId Assignment ID
 * @param {*} formData The form data containing the submission to post
 * @param {*} submissionIdCb Callback for the resulting submission ID
 * @param {*} successCb Callback for success
 * @param {*} errorCb Callback for error
 */
export const postUrlSubmission = (
  assignmentId: string,
  submitterId: string,
  formData: FormData,
  submissionIdCb: (arg0: string) => void,
  successCb: () => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost<SubmissionInfo>(
    `/assignment/${assignmentId}/submitter/${submitterId}/submission/url`,
    formData,
    (data) => {
      submissionIdCb(data.submissionId);
      successCb();
    },
    errorCb,
  );
};

/**
 * POST /assignment/{assignmentId}/submitter/{submitterId}/submission/text
 *
 * @param {*} assignmentId Assignment ID
 * @param {*} formData The form data containing the submission to post
 * @param {*} submissionIdCb Callback for the resulting submission ID
 * @param {*} successCb Callback for success
 * @param {*} errorCb Callback for error
 */
export const postTextSubmission = (
  assignmentId: string,
  submitterId: string,
  formData: FormData,
  submissionIdCb: (arg0: string) => void,
  successCb: () => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost<SubmissionInfo>(
    `/assignment/${assignmentId}/submitter/${submitterId}/submission/text`,
    formData,
    (data) => {
      submissionIdCb(data.submissionId);
      successCb();
    },
    errorCb,
  );
};

/**
 * POST /assignment/{assignmentId}/submission/presentation
 *
 * @param {*} assignmentId Assignment ID
 * @param {*} submissionIdCb Callback for the resulting submission ID
 * @param {*} successCb Callback for success
 * @param {*} errorCb Callback for error
 */
export const postPresentationSubmission = (
  assignmentId: string,
  submitterId: string,
  submissionIdCb: (arg0: string) => void,
  successCb: () => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost<SubmissionInfo>(
    `/assignment/${assignmentId}/submitter/${submitterId}/submission/presentation`,
    {},
    (data) => {
      submissionIdCb(data.submissionId);
      successCb();
    },
    errorCb,
  );
};

/**
 * DELETE /submission/{submissionId}
 *
 * @param {*} submissionId Submission ID
 * @param {*} successCb Callback for success
 * @param {*} errorCb Callback for error
 */
export const deleteSubmission = (submissionId: string, successCb: () => void, errorCb = defaultErrorCb): void => {
  requestDelete(`/submission/${submissionId}`, successCb, errorCb);
};

/**
 * POST /submission/{submissionId}
 *
 * @param {*} submissionId The submission ID of the file to be updated
 * @param {*} formData The form data containing the submission to post
 * @param {*} submissionIdCb Callback for the resulting submission ID
 * @param {*} successCb Callback for success
 * @param {*} errorCb Callback for error
 */
export const updateFileSubmission = (
  submissionId: string,
  formData: FormData,
  submissionIdCb: (arg0: string) => void,
  successCb: () => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost<SubmissionInfo>(
    `/submission/${submissionId}`,
    formData,
    (data) => {
      submissionIdCb(data.submissionId);
      successCb();
    },
    errorCb,
  );
};

/**
 * POST /submission/{submissionId}/url
 *
 * @param {*} submissionId The submission ID of the file to be updated
 * @param {*} formData The form data containing the submission to post
 * @param {*} submissionIdCb Callback for the resulting submission ID
 * @param {*} successCb Callback for success
 * @param {*} errorCb Callback for error
 */
export const updateUrlSubmission = (
  submissionId: string,
  formData: FormData,
  submissionIdCb: (arg0: string) => void,
  successCb: () => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost<SubmissionInfo>(
    `/submission/${submissionId}/url`,
    formData,
    (data) => {
      submissionIdCb(data.submissionId);
      successCb();
    },
    errorCb,
  );
};

/**
 * POST /submission/{submissionId}/text
 *
 * @param {*} submissionId The submission ID of the file to be updated
 * @param {*} formData The form data containing the submission to post
 * @param {*} submissionIdCb Callback for the resulting submission ID
 * @param {*} successCb Callback for success
 * @param {*} errorCb Callback for error
 */
export const updateTextSubmission = (
  submissionId: string,
  formData: FormData,
  submissionIdCb: (arg0: string) => void,
  successCb: () => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost<SubmissionInfo>(
    `/submission/${submissionId}/text`,
    formData,
    (data) => {
      submissionIdCb(data.submissionId);
      successCb();
    },
    errorCb,
  );
};

/**
 * POST /submission/{submissionId}/confirm
 *
 * @param {*} submissionId The submission ID (of submission to confirm)
 * @param {*} errorCb Callback for error
 */
export const confirmSubmission = (submissionId: string, errorCb = defaultErrorCb): void => {
  requestPost(`/submission/${submissionId}/confirm`, {}, () => undefined, errorCb);
};

/**
 * GET /results/assignment/{assignmentId}/details
 *
 * @param assignmentId Assignment ID
 * @param successCb Callback for data
 * @param errorCb Callback for error
 */
export const getInstructorResultsDetails = (
  assignmentId: string,
  successCb: (arg0: AssignmentDetailedResults[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet<AssignmentDetailedResults[]>(`/results/assignment/${assignmentId}/details`, successCb, errorCb);
};

/**
 * GET /results/assignment/{assignmentId}
 *
 * @param assignmentId Assignment ID
 * @param successCb Callback for data
 * @param errorCb Callback for error
 */
export const getInstructorResultsAll = (
  assignmentId: string,
  successCb: (arg0: Result[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet<Result[]>(`/results/assignment/${assignmentId}`, successCb, errorCb);
};

/**
 * POST /results/assignment/{assignmentId}/release
 *
 * @param {*} assignmentId The assignment ID
 * @param {*} successCb Callback for success
 * @param {*} errorCb Callback for error
 */
export const releaseResults = (assignmentId: string, successCb: () => void, errorCb = defaultErrorCb): void => {
  requestPost(`/results/assignment/${assignmentId}/release`, {}, successCb, errorCb);
};

/**
 * GET /results/assignment/{assignmentId}/calculate
 *
 * @param id Assignment ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const recalculateGrades = (assignmentId: string, successCb: () => void, errorCb = defaultErrorCb): void => {
  requestGet(`/results/assignment/${assignmentId}/calculate`, successCb, errorCb);
};

/**
 * POST ​/submission​/{submissionId}​/late​/waive
 *
 * @param {*} submissionId The submission ID
 * @param {*} successCb Callback for success
 * @param {*} errorCb Callback for error
 */
export const waiveLatePenalty = (submissionId: string, successCb: () => void, errorCb = defaultErrorCb): void => {
  requestPost(`/submission/${submissionId}/late/waive`, {}, successCb, errorCb);
};

/**
 * POST /results/assignment/{assignmentId}/user/{userId}/override/grade
 *
 * @param {*} assignmentId Assignment ID
 * @param {*} userId User ID
 * @param {*} reqData The request data to post
 * @param {*} successCb Callback for success
 * @param {*} errorCb Callback for error
 */
export const postOverrideGrade = (
  assignmentId: string,
  userId: string,
  reqData: Grade,
  successCb: (arg0: Result) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/results/assignment/${assignmentId}/user/${userId}/override/grade`, reqData, successCb, errorCb);
};

/**
 * POST ​/results​/assignment​/{assignmentId}​/user​/{userId}​/override​/asyncResult
 *
 * @param {*} assignmentId Assignment ID
 * @param {*} userId User ID
 * @param {*} reqData The request data to post
 * @param {*} successCb Callback for success
 * @param {*} errorCb Callback for error
 */
export const postOverrideAsyncGrade = (
  assignmentId: string,
  userId: string,
  reqData: { asyncResult: AsyncResult; user: User },
  successCb: (arg0: Result) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/results/assignment/${assignmentId}/user/${userId}/override/asyncResult`, reqData, successCb, errorCb);
};

/**
 * GET /course/{courseId}/progress
 *
 * @param courseId Course ID
 * @param successCb Callback for data
 * @param errorCb Callback for error
 */
export const getCourseProgress = (
  courseId: string,
  successCb: (arg0: AssignmentProgress[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet<AssignmentProgress[]>(`/course/${courseId}/progress`, successCb, errorCb);
};

/**
 * GET /course/{courseId}/roster/instructors
 *
 * @param courseId Course ID
 * @param successCb Callback for data
 * @param errorCb Callback for error
 */
export const getRosterInstructors = (
  courseId: string,
  successCb: (arg0: CourseUser[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/course/${courseId}/roster/instructors`, successCb, errorCb);
};

/**
 * GET /course/{courseId}/roster/students
 *
 * @param courseId Course ID
 * @param successCb Callback for data
 * @param errorCb Callback for error
 */
export const getRosterStudents = (
  courseId: string,
  successCb: (arg0: CourseUser[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet<CourseUser[]>(`/course/${courseId}/roster/students`, successCb, errorCb);
};

/**
 * GET /course/{courseId}/user/{userId}/roster/students
 *
 * @param courseId Course ID
 * @param userId User ID
 * @param successCb Callback for data
 * @param errorCb Callback for error
 */
export const getSectionedRosterStudents = (
  courseId: string,
  userId: string,
  successCb: (arg0: CourseUser[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet<CourseUser[]>(`/course/${courseId}/user/${userId}/roster/students`, successCb, errorCb);
};

/**
 * POST /course/{courseId}/roster/instructors
 *
 * @param {*} courseId Course ID
 * @param {*} reqData The request data to post
 * @param {*} successCb Callback for the resulting content ID
 * @param {*} errorCb Callback for error
 */
export const postAddInstructors = (
  courseId: string,
  reqData: { firstName: string; lastName: string; email: string }[],
  successCb: (arg0: { course: Course } & CourseUser) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/course/${courseId}/roster/instructors`, reqData, successCb, errorCb);
};

/**
 * POST /course/{courseId}/roster/students
 *
 * @param {*} courseId Course ID
 * @param {*} reqData The request data to post
 * @param {*} successCb Callback for the resulting content ID
 * @param {*} errorCb Callback for error
 */
export const postAddStudents = (
  courseId: string,
  reqData: { firstName: string; lastName: string; email: string }[],
  successCb: (arg0: { course: Course } & CourseUser) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/course/${courseId}/roster/students`, reqData, successCb, errorCb);
};

/**
 * GET /course/{courseId}/roster
 *
 * @param courseId Course ID
 * @param successCb Callback for data
 * @param errorCb Callback for error
 */
export const getRoster = (
  courseId: string,
  successCb: (arg0: CourseUser[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/course/${courseId}/roster`, successCb, errorCb);
};

/**
 * GET /assignment/{assignmentId}/progress
 *
 * @param assignmentId Assignment ID
 * @param successCb Callback for data
 * @param errorCb Callback for error
 */
export const getAssignmentProgress = (
  assignmentId: string,
  successCb: (arg0: AssignmentProgress[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/${assignmentId}/progress`, successCb, errorCb);
};

/**
 * DELETE /course/{courseId}/roster/user/{userId}
 *
 * @param userId User ID
 * @param courseId Course ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const deleteUserFromCourse = (
  userId: string,
  courseId: string,
  successCb: () => void,
  errorCb = defaultErrorCb,
): void => {
  requestDelete(`/course/${courseId}/roster/user/${userId}`, successCb, errorCb);
};

/**
 * GET /course/{courseId}/student/{userId}
 *
 * @param courseId Course ID
 * @param userId User ID
 * @param successCb Callback for User data
 * @param errorCb Callback for error
 */
export const getCourseUser = (
  courseId: string,
  userId: string,
  dataCb: (arg0: CourseUser) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/course/${courseId}/student/${userId}`, dataCb, errorCb);
};

/**
 * GET /course/{courseId}/assignments
 *
 * @param courseId Course ID
 * @param successCb Callback for assignment data
 * @param errorCb Callback for error
 */
export const getCourseAssignments = (
  courseId: string,
  successCb: (arg0: Assignment[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/course/${courseId}/assignments`, successCb, errorCb);
};

/**
 * GET /course/user/memberships
 *
 * @param pageQueryParams Object containing query parameters
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getUserCourseMemberships = (
  pageQueryParams: PageQueryParams,
  successCb: (arg0: PageQuery<CourseUser>) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/course/user/memberships?${new URLSearchParams(pageQueryParams).toString()}`, successCb, errorCb);
};

/**
 * POST /course
 *
 * @param reqData Course data for request
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const createCourse = (reqData: Course, successCb: (arg0: Course) => void, errorCb = defaultErrorCb): void => {
  requestPost(`/course`, reqData, successCb, errorCb);
};

/**
 * POST /course/{courseId}
 *
 * @param courseId Course ID
 * @param reqData Course data for request
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const updateCourse = (
  courseId: string,
  reqData: Course,
  successCb: (arg0: Course) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/course/${courseId}`, reqData, successCb, errorCb);
};

/**
 * POST /course/{courseId}/copy
 *
 * @param courseId Course ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const copyCourse = (
  courseId: string,
  reqData: Course,
  successCb: (arg0: Course & DeepLink) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/course/${courseId}/copy`, reqData, successCb, errorCb);
};

/**
 * DELETE /course/{courseId}
 *
 * @param courseId Course ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const deleteCourse = (courseId: string, successCb: () => void, errorCb = defaultErrorCb): void => {
  requestDelete(`/course/${courseId}`, successCb, errorCb);
};

/**
 * POST /course/{courseId}/archive
 *
 * @param courseId Course ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const archiveCourse = (courseId: string, successCb: (arg0: Course) => void, errorCb = defaultErrorCb): void => {
  requestPost(`/course/${courseId}/archive`, undefined, successCb, errorCb);
};

/**
 * POST /course/{courseId}/activate
 *
 * @param courseId Course ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const activateCourse = (courseId: string, successCb: (arg0: Course) => void, errorCb = defaultErrorCb): void => {
  requestPost(`/course/${courseId}/activate`, undefined, successCb, errorCb);
};

/**
 * POST /course/enrollmentPassword/{enrollmentPasscode}/enroll
 * @param code Course code
 */

export const joinCourseViaCode = (
  code: string,
  successCb: (arg0: Membership) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/course/enrollmentPassword/${code}/enroll`, undefined, successCb, errorCb);
};

/**
 * POST /course/{courseId}/assignment
 *
 * @param courseId Course ID
 * @param reqData Assignment data for request
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const createAssignment = (
  courseId: string,
  reqData: Assignment,
  successCb: (arg0: Assignment & DeepLink) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/course/${courseId}/assignment`, reqData, successCb, errorCb);
};

/**
 * GET /course/{courseId}/assignment/user/copyable
 *
 * @param courseId Course ID
 * @param successCb Callback for assignment data
 * @param errorCb Callback for error
 */
export const getUserCopyableAssignments = (
  courseId: string,
  successCb: (arg0: Assignment[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/course/${courseId}/assignment/user/copyable`, successCb, errorCb);
};

/**
 * POST /assignment/{sourceAssignmentId}/copy
 *
 * @param sourceAssignmentId ID of assignment to copy from
 * @param reqData Assignment data for request
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const copyAssignment = (
  sourceAssignmentId: string,
  reqData: Assignment,
  successCb: (arg0: Assignment & DeepLink) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/assignment/${sourceAssignmentId}/copy`, reqData, successCb, errorCb);
};

/**
 * GET /course/{courseId}
 *
 * @param courseId ID of course
 * @param successCb Callback for data
 * @param errorCb Callback for error
 */
export const getCourse = (courseId: string, successCb: (arg0: Course) => void, errorCb = defaultErrorCb): void => {
  requestGet(`/course/${courseId}`, successCb, errorCb);
};

/**
 * GET /assignment/{assignmentId}
 *
 * @param assignmentId ID of assignment
 * @param dataCb Callback for data
 * @param errorCb Callback for error
 */
export const getAssignmentData = (
  assignmentId: string,
  successCb: (arg0: Assignment) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/${assignmentId}`, successCb, errorCb);
};

/**
 * PUT /assignment/{assignmentId}
 *
 * @param assignmentId ID of assignment to edit
 * @param reqData Assignment data
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const editAssignment = (
  assignmentId: string,
  reqData: Assignment,
  successCb: (arg0: Assignment) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPut(`/assignment/${assignmentId}`, reqData, successCb, errorCb);
};

/**
 * POST /login
 *
 * @param credentials Credentials for login
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const login = (credentials: Credentials, successCb: (arg0: LoginResponse) => void, errorCb = defaultErrorCb): void => {
  requestPost(`/login`, credentials, successCb, errorCb);
};

/**
 * POST /mfa/login
 *
 * @param MfaCredentials MfaCredentials for verification
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const mfaLogin = (credentials: MfaCredentials, successCb: (arg0: LoginResponse) => void, errorCb = defaultErrorCb): void => {
  requestPost(`/mfa/login`, credentials, successCb, errorCb);
};

/**
 * POST /mfa/verify - this is used to confirm MFA activation
 *
 * @param MfaCredentials MfaCredentials for verification
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const mfaVerify = (credentials: MfaCredentials, successCb: (arg0: LoginResponse) => void, errorCb = defaultErrorCb): void => {
  requestPost(`/mfa/verify`, credentials, successCb, errorCb);
};

/**
 * POST /mfa/email
 *
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const mfaEmail = (credentials: MfaCredentials, successCb: () => void, errorCb = defaultErrorCb): void => {
  requestPost(`/mfa/email`,credentials, successCb, errorCb);
};

/**
 * GET /mfa/enroll/code
 *
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const mfaEnrollCode = (successCb: (arg0: MfaEnrollment) => void, errorCb = defaultErrorCb): void => {
  requestGet(`/mfa/enroll/info`, successCb, errorCb);
};

/**
 * POST /mfa/disable
 *
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const mfaDisable = ({}, successCb: (arg0: User) => void, errorCb = defaultErrorCb): void => {
  requestPost(`/mfa/disable`,{}, successCb, errorCb);
};

/**
 * POST /admin/user/{userId}/mfa/disable
 *
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const adminMfaDisable = (userId: string, successCb: () => void, errorCb = defaultErrorCb): void => {
  requestPost(`/admin/user/${userId}/mfa/disable`,{}, successCb, errorCb);
};

/**
 * POST /admin/user/{userId}/mfa/reset
 *
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const adminMfaReset = (userId: string, successCb: () => void, errorCb = defaultErrorCb): void => {
  requestPost(`/admin/user/${userId}/mfa/reset`,{}, successCb, errorCb);
};

/**
 * POST /register
 *
 * @param registrationData Data for user registration
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const register = (
  registrationData: UserRegistration,
  successCb: (arg0: User) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/register`, registrationData, successCb, errorCb);
};

/**
 * GET /logout
 *
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const logout = (successCb: () => void, errorCb = defaultErrorCb): void => {
  requestGet(`/logout`, successCb, errorCb);
};

/**
 * GET /me
 *
 * @param dataCb Callback for current authenticated user data
 * @param errorCb Callback for error
 */
export const getMe = (successCb: (arg0: User) => void, errorCb = defaultErrorCb): void => {
  requestGet(`/me`, successCb, errorCb);
};

/**
 * GET /assignment/{assignmentId}/user/progress
 *
 * @param assignmentId ID of assignment
 * @param successCb
 * @param errorCb
 */
export const getStudentAssignmentProgress = (
  assignmentId: string,
  successCb: (arg0: AssignmentProgress) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/${assignmentId}/user/progress`, successCb, errorCb);
};

/**
 * POST /assignment/{assignmentId}/review
 *
 * @param assignmentId ID of assignment
 * @param dataCb Callback for data
 * @param errorCb Callback for Error
 */
export const createNewReview = (
  assignmentId: string,
  successCb: (arg0: Review) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/assignment/${assignmentId}/review`, {}, successCb, errorCb);
};

/**
 * POST /course/{courseId}/user/{userId}/act_as
 *
 * @param courseId Course ID
 * @param userId User ID
 * @param actAsUrl URL to redirect to once ending act-as
 * @param successCb Callback for data
 * @param errorCb Callback for error
 */
export const actAs = (
  courseId: string,
  userId: string,
  actAsUrl: string,
  successCb: (arg0: User) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/course/${courseId}/user/${userId}/act_as`, { actAsUrl }, successCb, errorCb);
};

/**
 * POST /user/{userId}/act_as
 *
 * @param userId User ID
 * @param actAsUrl URL to redirect to once ending act-as
 * @param dataCb Callback for data
 * @param errorCb Callback for error
 */
export const adminActAs = (
  userId: string,
  actAsUrl: string,
  successCb: (arg0: User) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/user/${userId}/act_as`, { actAsUrl }, successCb, errorCb);
};

/**
 * GET /user/end_act_as
 *
 * @param successCb Callback for data
 * @param errorCb Callback for error
 */
export const endActAs = (successCb: (arg0: User) => void, errorCb = defaultErrorCb): void => {
  requestGet(`/user/end_act_as`, successCb, errorCb);
};

/**
 * GET /assignment/{assignmentId}/user/{userId}/progress
 *
 * @param assignmentId Assignment ID
 * @param userId User ID
 * @param successCb Callback for data
 * @param errorCb Callback for error
 */
export const getUserAssignmentProgress = (
  assignmentId: string,
  userId: string,
  successCb: (arg0: AssignmentProgress) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/${assignmentId}/user/${userId}/progress`, successCb, errorCb);
};

/**
 * PUT /user/{userId}
 *
 * @param userId ID of the user to edit
 * @param reqData Request Data
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const editUser = (
  userId: string,
  reqData: UserUpdateRequest,
  successCb: (arg0: User) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPut(`/user/${userId}`, reqData, successCb, errorCb);
};

/**
 * POST /assignment/{assignmentId}/end_phase
 *
 * @param assignmentId ID of assignment to end phase
 * @param successCb Callback for assignment data
 * @param errorCb Callback for error
 */
export const endPhase = (
  assignmentId: string,
  successCb: (arg0: Assignment) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/assignment/${assignmentId}/end_phase`, {}, successCb, errorCb);
};

/**
 * POST /assignment/{assignmentId}/unpublish
 *
 * @param assignmentId
 * @param successCb
 * @param errorCb
 */
export const unpublishAssignment = (
  assignmentId: string,
  successCb: (arg0: Assignment) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/assignment/${assignmentId}/unpublish`, {}, successCb, errorCb);
};

/**
 * POST /forgot_password
 *
 * @param email Email associated with account to password-reset
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const forgotPassword = (email: string, successCb: (arg0: User) => void, errorCb = defaultErrorCb): void => {
  requestPost(`/forgot_password`, { email }, successCb, errorCb);
};

/**
 * GET /user/reset_code/{passwordResetCode}
 *
 * @param passwordResetCode UUID for password reset
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const verifyPasswordResetCode = (
  passwordResetCode: string,
  successCb: () => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/user/reset_code/${passwordResetCode}`, successCb, errorCb);
};

/**
 * POST /user/update_password
 *
 * @param newPassword New password for update
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const updatePassword = (
  newPassword: string,
  successCb: (arg0: User) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/user/update_password`, { newPassword }, successCb, errorCb);
};

/**
 * DELETE /assignment/{assignmentId}
 *
 * @param assignmentId Assignment ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const deleteAssignment = (assignmentId: string, successCb: () => void, errorCb = defaultErrorCb): void => {
  requestDelete(`/assignment/${assignmentId}`, successCb, errorCb);
};

/**
 * GET /assignment/{assignmentId}/groups
 *
 * @param assignmentId Assignment ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getAssignmentGroups = (
  assignmentId: string,
  successCb: (arg0: Group[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/${assignmentId}/groups`, successCb, errorCb);
};

/**
 * DELETE /assignment/{assignmentId}/groups
 *
 * @param assignmentId Assignment ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const deleteAssignmentGroups = (assignmentId: string, successCb: () => void, errorCb = defaultErrorCb): void => {
  requestDelete(`/assignment/${assignmentId}/groups`, successCb, errorCb);
};

/**
 * PUT /assignment/{assignmentId}/groups/{groupId}
 *
 * @param assignmentId Assignment ID
 * @param groupId Group ID
 * @param reqData Group data
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const updateAssignmentGroup = (
  assignmentId: string,
  groupId: string,
  reqData: Group,
  successCb: (arg0: User) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPut(`/assignment/${assignmentId}/groups/${groupId}`, reqData, successCb, errorCb);
};

/**
 * GET /assignment/{assignmentId}/groups/users/ungrouped
 *
 * @param assignmentId Assignment ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getUngroupedUsers = (
  assignmentId: string,
  successCb: (arg0: User[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/${assignmentId}/groups/users/ungrouped`, successCb, errorCb);
};

/**
 * DELETE /assignment/{assignmentId}/groups/{groupId}
 *
 * @param assignmentId Assignment ID of group
 * @param groupId Group ID of group to delete
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const deleteGroup = (
  assignmentId: string,
  groupId: string,
  successCb: () => void,
  errorCb = defaultErrorCb,
): void => {
  requestDelete(`/assignment/${assignmentId}/groups/${groupId}`, successCb, errorCb);
};

/**
 * POST /assignment/{assignmentId}/groups/{groupId}/enroll
 *
 * @param assignmentId Assignment ID of group assignment
 * @param groupId Group ID of group to add users to
 * @param userIds List of User IDs of users to add to group
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const addUsersToGroup = (
  assignmentId: string,
  groupId: string,
  userIds: string[],
  successCb: (arg0: User) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/assignment/${assignmentId}/groups/${groupId}/enroll`, { userIds }, successCb, errorCb);
};

/**
 * POST /assignment/{assignmentId}/groups/{groupId}/user/{userId}
 *
 * @param assignmentId Assignment ID
 * @param userId User ID
 * @param groupId Group ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const addUserToGroup = (
  assignmentId: string,
  userId: string,
  groupId: string,
  successCb: (arg0: User) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/assignment/${assignmentId}/groups/${groupId}/user/${userId}`, {}, successCb, errorCb);
};

/**
 * POST /assignment/{assignmentId}/groups/group
 *
 * @param assignmentId Assignment ID
 * @param groupName Name of group
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const createGroup = (
  assignmentId: string,
  groupName: string,
  successCb: (arg0: User) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/assignment/${assignmentId}/groups/group`, { assignmentId, groupName }, successCb, errorCb);
};

/**
 * DELETE /assignment/{assignmentId}/groups/{groupId}/user/{userId}
 *
 * @param assignmentId Assignment ID
 * @param groupId Group ID
 * @param userId User ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const removeUserFromGroup = (
  assignmentId: string,
  groupId: string,
  userId: string,
  successCb: () => void,
  errorCb = defaultErrorCb,
): void => {
  requestDelete(`/assignment/${assignmentId}/groups/${groupId}/user/${userId}`, successCb, errorCb);
};

/**
 * GET /assignment/{assignmentId}/groups/roster
 *
 * @param assignmentId Assignment ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getGroupsRoster = (
  assignmentId: string,
  successCb: (arg0: UserWithGroupInfo[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/${assignmentId}/groups/roster`, successCb, errorCb);
};

/**
 * POST /assignment/{assignmentId}/groups/invite/{groupInviteId}/accept
 *
 * @param assignmentId Assignment ID
 * @param groupInviteId Group Invite ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const acceptGroupInvite = (
  assignmentId: string,
  groupInviteId: string,
  successCb: () => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/assignment/${assignmentId}/groups/invite/${groupInviteId}/accept`, null, successCb, errorCb);
};

/**
 * POST /assignment/{assignmentId}/groups/invite/{groupInviteId}/reject
 *
 * @param assignmentId Assignment ID
 * @param groupInviteId Group Invite ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const rejectGroupInvite = (
  assignmentId: string,
  groupInviteId: string,
  successCb: () => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/assignment/${assignmentId}/groups/invite/${groupInviteId}/reject`, null, successCb, errorCb);
};

/**
 * GET /assignment/{assignmentId}/groups/user/{userId}/pending
 *
 * @param assignmentId Assignment ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getMyPendingGroups = (
  assignmentId: string,
  successCb: (arg0: Group[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/${assignmentId}/groups/user/pending`, successCb, errorCb);
};

/**
 * POST /assignment/{assignmentId}/groups/import
 *
 * @param assignmentId Assignment ID
 * @param groups Parsed CSV of groups, as an array of string arrays
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const importGroups = (
  assignmentId: string,
  groups: string[][],
  successCb: (arg0: User) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/assignment/${assignmentId}/groups/import`, { groups }, successCb, errorCb);
};

/**
 * GET /assignment/{assignmentId}/groups/{groupId}/user/{userId}/promote
 *
 * @param assignmentId Assignment ID
 * @param groupId Group ID
 * @param userId User ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const promoteUserToGroupLeader = (
  assignmentId: string,
  groupId: string,
  userId: string,
  successCb: () => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/${assignmentId}/groups/${groupId}/user/${userId}/promote`, successCb, errorCb);
};

/**
 * GET /assignment/{assignmentId}/groups/{groupId}/user/{userId}/demote
 *
 * @param assignmentId Assignment ID
 * @param groupId Group ID
 * @param userId User ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const demoteGroupLeader = (
  assignmentId: string,
  groupId: string,
  userId: string,
  successCb: () => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/${assignmentId}/groups/${groupId}/user/${userId}/demote`, successCb, errorCb);
};

/**
 * GET /assignment/{assignmentId}/groups/copyable
 *
 * @param assignmentId Assignment ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getAssignmentsWithCopyableGroups = (
  assignmentId: string,
  successCb: (arg0: Assignment[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/${assignmentId}/groups/copyable`, successCb, errorCb);
};

/**
 * GET /assignment/{assignmentId}/groups/copy/{sourceAssignmentId}
 *
 * @param assignmentId Assignment ID
 * @param sourceAssignmentId Assignment ID of assignment to copy from
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const copyGroupsFromPreviousAssignment = (
  assignmentId: string,
  sourceAssignmentId: string,
  successCb: (arg0: Assignment[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/${assignmentId}/groups/copy/${sourceAssignmentId}`, successCb, errorCb);
};

/**
 * POST /assignment/{assignmentId}/groups/auto_create
 *
 * @param assignmentId Assignment ID
 * @param reqData Request data object, containing numberOfGroups and maxUsersPerGroup values
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const autoCreateGroups = (
  assignmentId: string,
  reqData: { numberOfGroups: number; maxUsersPerGroup: number },
  successCb: (arg0: User) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/assignment/${assignmentId}/groups/auto_create`, reqData, successCb, errorCb);
};

/**
 * POST /user/resend_verification
 *
 * @param username Username
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const resendVerification = (username: string, successCb: () => void, errorCb = defaultErrorCb): void => {
  requestPost(`/resend_verification`, { username }, successCb, errorCb);
};

/**
 * GET /user/{userId}/verify/{verificationCode}
 *
 * @param userId User ID
 * @param verificationCode Email verification code
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const verifyUserEmail = (
  userId: string,
  verificationCode: string,
  successCb: (arg0: User) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/user/${userId}/verify/${verificationCode}`, successCb, errorCb);
};

/**
 * GET /user/{userId}/activate/{activationCode}
 *
 * @param userId User ID
 * @param activationCode Activation Code
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const activateUserAccount = (
  userId: string,
  activationCode: string,
  successCb: (arg0: User) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/user/${userId}/activate/${activationCode}`, successCb, errorCb);
};

/**
 * POST /rating/{ratingId}/resource/file
 *
 * @param ratingId Rating ID
 * @param formData The form data containing the resource file (named "resource")
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const postRatingResourceFile = (
  ratingId: string,
  formData: FormData,
  successCb: () => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/rating/${ratingId}/resource/file`, formData, successCb, errorCb);
};

/**
 * GET /rating/${ratingId}/resource/file
 *
 * @param ratingId Rating ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getRatingResourceFile = (
  ratingId: string,
  successCb: (arg0: File) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet<string>(
    `/rating/${ratingId}/resource/file`,
    (data, res) => {
      const file = new File([data], 'resource', {
        type: res.headers['content-type'],
      });
      successCb(file);
    },
    errorCb,
    'blob',
  );
};

/**
 * GET /assignment/{assignmentId}/results/user/resources
 *
 * @param assignmentId Assignment ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getResourcesForStudent = (
  assignmentId: string,
  successCb: (arg0: RatingResource[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/${assignmentId}/results/user/resources`, successCb, errorCb);
};

/**
 * GET /assignment/{assignmentId}/results/benchmark
 *
 * @param assignmentId Assignment ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getSubmissionsForBenchmarking = (
  assignmentId: string,
  successCb: (arg0: BenchmarkGrade[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/${assignmentId}/results/benchmark`, successCb, errorCb);
};

/**
 * POST /assignment/{assignmentId}/results/benchmark
 *
 * @param assignmentId Assignment ID
 * @param benchmarkGrades Benchmark grades to save
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const postBenchmarkGrades = (
  assignmentId: string,
  benchmarkGrades: BenchmarkGrade[],
  successCb: (data: BenchmarkGrade[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/assignment/${assignmentId}/results/benchmark`, benchmarkGrades, successCb, errorCb);
};

/**
 * GET /assignment/{assignmentId}/results/benchmark/not_selected
 *
 * @param assignmentId Assignment ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getUnselectedBenchmarkSubmissions = (
  assignmentId: string,
  successCb: (arg0: BenchmarkGrade[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/${assignmentId}/results/benchmark/not_selected`, successCb, errorCb);
};

/**
 * GET: /assignment/{assignmentId}/submission/{submissionId}/review/items
 *
 * @param assignmentId Assignment ID
 * @param submissionId Submission ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getReviewItemsForSubmission = (
  assignmentId: string,
  submissionId: string,
  successCb: (arg0: ReviewByItem[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/${assignmentId}/submission/${submissionId}/review/items`, successCb, errorCb);
};

/**
 * GET: /assignment/{assignmentId}/user/{userId}/review/items
 *
 * @param assignmentId
 * @param userId
 * @param successCb
 * @param errorCb
 */
export const getReviewItemsOfUser = (
  assignmentId: string,
  userId: string,
  successCb: (arg0: ReviewByItem[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/${assignmentId}/user/${userId}/review/items`, successCb, errorCb);
};

/**
 * POST /assignment/{assignmentId}/submission/{submissionId}/review/instructor
 *
 * @param assignmentId Assignment ID
 * @param submissionId Submission ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const createInstructorReview = (
  assignmentId: string,
  submissionId: string,
  successCb: (data: Review) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/assignment/${assignmentId}/submission/${submissionId}/review/instructor`, {}, successCb, errorCb);
};

/**
 * GET /course/{courseId}/assignments/all/user/progress
 *
 * @param courseId Course ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getUserAssignmentProgressForCourse = (
  courseId: string,
  successCb: (arg0: { [index: string]: AssignmentProgress }) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/course/${courseId}/assignments/all/user/progress`, successCb, errorCb);
};

/**
 * POST /course/{courseId}/user/code/redeem
 *
 * @param courseId Course ID
 * @param purchaseCode Student course purchase code
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const redeemStudentPurchaseCode = (
  courseId: string,
  purchaseCode: string,
  successCb: () => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/course/${courseId}/user/code/redeem`, { purchaseCode }, successCb, errorCb);
};

/**
 * GET /admin/reset_db
 */
export const resetTestDb = (): void => {
  requestGet(
    `/admin/reset_db`,
    () => {
      console.error('Test database has been reset');
    },
    defaultErrorCb,
  );
};

/**
 * GET /assignment/{assignmentId}/eval/{target}/rubric
 *
 * @param {number} assignmentId Assignment ID
 * @param {string} target Evaluation target
 * @param {function(array): *} itemsCb Callback for rubric items
 * @param {function(object): *} errorCb Callback for error
 */
export const getPeerEvalRubricItems = (
  assignmentId: string,
  target: EvaluationTarget,
  itemsCb: (arg0: Rubric) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet<AssignmentRubric>(
    `/assignment/${assignmentId}/eval/${target}/rubric`,
    (data) => itemsCb(data.items),
    errorCb,
  );
};

/**
 * POST /assignment/{assignmentId}/eval/{target}/comment
 *
 * @param {*} assignmentId ID of rubric's assignment to add to
 * @param {*} target Evaluation target
 * @param {*} order The order of the new comment prompt
 * @param {*} commentData Comment data
 * @param {*} successCb Callback for the response data
 * @param {*} errorCb Callback for error
 */
export const postEvalRubricCommentNew = (
  assignmentId: string,
  target: EvaluationTarget,
  order: number,
  commentData: Comment,
  successCb: (arg0: Comment) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(
    `/assignment/${assignmentId}/eval/${target}/comment`,
    {
      ...commentData,
      assignmentId,
      order,
    },
    successCb,
    errorCb,
  );
};

/**
 * POST /assignment/{assignmentId}/eval/{target}/rating
 *
 * @param {*} assignmentId ID of rubric's assignment to add to
 * @param {*} target Evaluation target
 * @param {*} order The order of the new rating prompt
 * @param {*} ratingData Rating data
 * @param {*} dataCb Callback for the response data
 * @param {*} errorCb Callback for error
 */
export const postEvalRubricRatingNew = (
  assignmentId: string,
  target: EvaluationTarget,
  order: number,
  ratingData: Rating,
  successCb: (arg0: Rating) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(
    `/assignment/${assignmentId}/eval/${target}/rating`,
    {
      ...ratingData,
      assignmentId,
      order,
    },
    successCb,
    errorCb,
  );
};

/**
 * GET /assignment/{assignmentId}/eval/{target}/rubric/comments
 *
 * @param assignmentId Assignment ID
 * @param target Evaluation Target
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getEvalRubricComments = (
  assignmentId: string,
  target: EvaluationTarget,
  successCb: (arg0: Comment[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/${assignmentId}/eval/${target}/rubric/comments`, successCb, errorCb);
};

/**
 * GET /assignment/{assignmentId}/eval/{target}/rubric/ratings
 *
 * @param assignmentId Assignment ID
 * @param target Evaluation Target
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getEvalRubricRatings = (
  assignmentId: string,
  target: EvaluationTarget,
  successCb: (arg0: Rating[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/${assignmentId}/eval/${target}/rubric/ratings`, successCb, errorCb);
};

/**
 * GET /assignment/{assignmentId}/evals/user/{userId}/item_catalog
 *
 * @param assignmentId Assignment ID
 * @param userId User ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getEvalScoreItemCatalog = (
  assignmentId: string,
  userId: string,
  successCb: (arg0: EvaluationScoreItemCatalog) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/${assignmentId}/evals/user/${userId}/item_catalog`, successCb, errorCb);
};

/**
 * GET /eval/{peerEvalId}
 *
 * @param peerEvalId Team Member Evaluation ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getPeerEval = (
  peerEvalId: string,
  successCb: (arg0: Evaluation) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/eval/${peerEvalId}`, successCb, errorCb);
};

/**
 * GET /assignment/{assignmentId}/eval/{peerEvalId}/score_timeline
 *
 * @param assignmentId Assignment ID
 * @param peerEvalId Team Member Evaluation ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getEvaluationScoreTimelines = (
  assignmentId: string,
  peerEvalId: string,
  successCb: (arg0: EvaluationScoreTimeline[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/${assignmentId}/eval/${peerEvalId}/score_timeline`, successCb, errorCb);
};

/**
 * GET /eval/{peerEvaluationId}/map/comment
 *
 * @param peerEvaluationId Team Member Evaluation ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getEvaluationCommentMap = (
  peerEvaluationId: string,
  successCb: (arg0: { [index: string]: string }) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/eval/${peerEvaluationId}/map/comment`, successCb, errorCb);
};

/**
 * GET /eval/{peerEvaluationId}/map/rating
 *
 * @param peerEvaluationId Team Member Evaluation ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getEvaluationRatingMap = (
  peerEvaluationId: string,
  successCb: (arg0: { [index: string]: number }) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/eval/${peerEvaluationId}/map/rating`, successCb, errorCb);
};

/**
 * PUT /eval/{peerEvaluationId}/map/comment
 *
 * @param peerEvaluationId Team Member Evaluation ID
 * @param commentMap Map of comment ID to comment
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const updateEvaluationComments = (
  peerEvaluationId: string,
  commentMap: { [index: string]: string },
  successCb: (arg0: Evaluation) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPut(`/eval/${peerEvaluationId}/comments`, commentMap, successCb, errorCb);
};

/**
 * PUT /eval/{peerEvaluationId}/map/rating
 *
 * @param peerEvaluationId Team Member Evaluation ID
 * @param ratingMap Map of rating ID to score
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const updateEvaluationRatings = (
  peerEvaluationId: string,
  ratingMap: { [index: string]: number },
  successCb: (arg0: Evaluation) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPut(`/eval/${peerEvaluationId}/ratings`, ratingMap, successCb, errorCb);
};

/**
 * POST /assignment/{assignmentId}/eval/user/{userId}/peer/{peerId}/member
 *
 * @param assignmentId Assignment ID
 * @param userId User ID
 * @param peerId Peer ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const createMemberPeerEval = (
  assignmentId: string,
  userId: string,
  peerId: string,
  successCb: (arg0: Evaluation) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/assignment/${assignmentId}/eval/user/${userId}/peer/${peerId}/member`, {}, successCb, errorCb);
};

/**
 * GET /assignment/{assignmentId}/evals/user/{userId}/received/averages
 *
 * @param assignmentId Assignment ID
 * @param userId User ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getAssignmentEvalAverages = (
  assignmentId: string,
  userId: string,
  successCb: (arg0: AverageRating[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/${assignmentId}/evals/user/${userId}/received/averages`, successCb, errorCb);
};

/**
 * GET /assignment/{assignmentId}/evals/user/{userId}/received/group/averages
 *
 * @param assignmentId Assignment ID
 * @param userId User ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getAssignmentUserOverallGroupEvalAverages = (
  assignmentId: string,
  userId: string,
  successCb: (arg0: AverageRating[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/${assignmentId}/evals/user/${userId}/received/group/averages`, successCb, errorCb);
};

/**
 * GET /assignment/{assignmentId}/evals/user/{userId}/received/member/averages
 *
 * @param assignmentId Assignment ID
 * @param userId User ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getAssignmentUserGroupMemberEvalAverages = (
  assignmentId: string,
  userId: string,
  successCb: (arg0: AverageRating[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/${assignmentId}/evals/user/${userId}/received/member/averages`, successCb, errorCb);
};

/**
 * GET /assignment/{assignmentId}/evals/user/{userId}/received/leader/averages
 *
 * @param assignmentId Assignment ID
 * @param userId User ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getAssignmentUserGroupLeaderEvalAverages = (
  assignmentId: string,
  userId: string,
  successCb: (arg0: AverageRating[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/${assignmentId}/evals/user/${userId}/received/leader/averages`, successCb, errorCb);
};

/**
 * GET /assignment/{assignmentId}/results/user/rating_scores
 *
 * @param assignmentId Assignment ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getStudentRatingScores = (
  assignmentId: string,
  successCb: (arg0: UserRatingScore[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/${assignmentId}/results/user/rating_scores`, successCb, errorCb);
};

/**
 * GET: /assignment/{assignmentId}/results/rating_scores/averages
 *
 * @param assignmentId Assignment ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getAssignmentAverageRatingScores = (
  assignmentId: string,
  successCb: (arg0: RatingScore[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/${assignmentId}/results/rating_scores/averages`, successCb, errorCb);
};

/**
 * GET /assignment/{assignmentId}/results/user/comments/by_prompt
 *
 * @param assignmentId Assignment ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getStudentCommentsReceivedByPrompt = (
  assignmentId: string,
  successCb: (arg0: CommentWithReviewComments[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/${assignmentId}/results/user/comments/by_prompt`, successCb, errorCb);
};

/**
 * GET /assignment/{assignmentId}/results/eval/user/comments/by_prompt
 *
 * @param assignmentId Assignment ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getStudentEvalCommentsReceivedByPrompt = (
  assignmentId: string,
  successCb: (arg0: CommentWithReviewComments[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/${assignmentId}/results/eval/user/comments/by_prompt`, successCb, errorCb);
};

/**
 * GET /assignment/{assignmentId}/results/eval/{target}/rating_scores/averages
 *
 * @param assignmentId Assignment ID
 * @param target Evaluation Target
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getEvalRatingScoresByTarget = (
  assignmentId: string,
  target: EvaluationTarget,
  successCb: (arg0: RatingScore[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/${assignmentId}/results/eval/${target}/rating_scores/averages`, successCb, errorCb);
};

/**
 * GET /results/assignment/{assignmentId}/details/evals/{target}
 *
 * @param assignmentId Assignment ID
 * @param target Evaluation target
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getInstructorEvalResultsDetails = (
  assignmentId: string,
  target: EvaluationTarget,
  successCb: (arg0: AssignmentDetailedResults[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/results/assignment/${assignmentId}/details/evals/${target}`, successCb, errorCb);
};

/**
 * GET /assignment/{assignmentId}/events/daily
 *
 * @param assignmentId Assignment ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getDailyAssignmentTaskEvents = (
  assignmentId: string,
  successCb: (arg0: DailyTaskEvents[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/${assignmentId}/events/daily`, successCb, errorCb);
};

/**
 * GET /assignment/{assignmentId}/user/{userId}/eval/items
 *
 * @param assignmentId Assignment ID
 * @param userId User ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getEvalItemsByUser = (
  assignmentId: string,
  userId: string,
  successCb: (arg0: EvaluationByItem[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/${assignmentId}/user/${userId}/eval/items`, successCb, errorCb);
};

/**
 * GET /assignment/{assignmentId}/peer/{peerId}/eval/items
 *
 * @param assignmentId Assignment ID
 * @param peerId Peer ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getEvalItemsByPeer = (
  assignmentId: string,
  peerId: string,
  successCb: (arg0: EvaluationByItem[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/${assignmentId}/peer/${peerId}/eval/items`, successCb, errorCb);
};

/**
 * GET /assignment/{assignmentId}/submissions/instructor
 *
 * @param assignmentId Assignment ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getInstructorSubmissionInfos = (
  assignmentId: string,
  successCb: (data: SubmissionInfo[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/${assignmentId}/submissions/instructor`, successCb, errorCb);
};

/**
 * POST /assignment/{assignmentId}/results/user/{userId}/force_result
 *
 * @param assignmentId Assignment ID
 * @param userId User ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const forceAsyncResult = (
  assignmentId: string,
  userId: string,
  successCb: () => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/assignment/${assignmentId}/results/user/${userId}/force_result`, {}, successCb, errorCb);
};

/**
 * DELETE /assignment/{assignmentId}/results/user/{userId}/result
 *
 * @param assignmentId Assignment ID
 * @param userId User ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const deleteAsyncResult = (
  assignmentId: string,
  userId: string,
  successCb: () => void,
  errorCb = defaultErrorCb,
): void => {
  requestDelete(`/assignment/${assignmentId}/results/user/${userId}/result`, successCb, errorCb);
};

/**
 * POST /assignment/{assignmentId}/review/self
 *
 * @param assignmentId Assignment ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const createSelfReview = (
  assignmentId: string,
  successCb: (arg0: Review) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/assignment/${assignmentId}/review/self`, {}, successCb, errorCb);
};

/**
 * POST /purchase
 *
 * @param seats Number of seats for the purchase
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const createPurchase = (seats: number, successCb: (arg0: Purchase) => void, errorCb = defaultErrorCb): void => {
  requestPost(`/purchase`, { seats }, successCb, errorCb);
};

/**
 * GET /purchase/{purchaseId}/checkout_token
 *
 * @param purchaseId Purchase ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getPurchaseCheckoutToken = (
  purchaseId: string,
  successCb: (data: { url: string; sessionId: string }) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/purchase/${purchaseId}/checkout_token`, successCb, errorCb);
};

/**
 * POST /specialPurchase/{code}/purchase
 *
 * @param code Purchase/Discount Code
 * @param seats Number of seats for the purchase
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const createSpecialPurchaseWithCode = (
  code: string,
  seats: number | undefined,
  successCb: (arg0: Purchase) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/specialPurchase/${code}/purchase`, seats !== undefined ? { seats } : {}, successCb, errorCb);
};

/**
 * GET /specialPurchase/{code}
 *
 * @param code Purchase/Discount Code
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getSpecialPurchaseByCode = (
  code: string,
  successCb: (data: SpecialPurchase) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/specialPurchase/${code}`, successCb, errorCb);
};

/**
 * POST /course/{courseId}/purchase
 *
 * @param courseId Course ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const createStudentPurchase = (
  courseId: string,
  successCb: (arg0: Purchase) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/course/${courseId}/purchase`, {}, successCb, errorCb);
};

/**
 * GET /purchase/{purchaseId}
 *
 * @param purchaseId Purchase ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getPurchase = (
  purchaseId: string,
  successCb: (data: Purchase) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/purchase/${purchaseId}`, successCb, errorCb);
};

/**
 * GET /user/purchases
 *
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getMyPurchaseHistory = (successCb: (data: Purchase[]) => void, errorCb = defaultErrorCb): void => {
  requestGet(`/user/purchases`, successCb, errorCb);
};

/**
 * POST /purchase/{purchaseId}/sharing/user
 *
 * @param purchaseId Purchase Id
 * @param email Email of user to share purchase with
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const sharePurchase = (
  purchaseId: string,
  email: string,
  successCb: (arg0: Purchase) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/purchase/${purchaseId}/sharing/user`, { email }, successCb, errorCb);
};

/**
 * DELETE /purchase/{purchaseId}/sharing/{sharedPurchaseId}
 *
 * @param purchaseId Purchase ID
 * @param sharedPurchaseId Shared Purchase ID
 * @param successCb Callback for succcess
 * @param errorCb Callback for error
 */
export const unsharePurchase = (
  purchaseId: string,
  sharedPurchaseId: string,
  successCb: (arg0: Purchase) => void,
  errorCb = defaultErrorCb,
): void => {
  requestDelete(`/purchase/${purchaseId}/sharing/${sharedPurchaseId}`, successCb, errorCb);
};

/**
 * GET /user/purchases/shared
 *
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getMySharedPurchases = (successCb: (data: Purchase[]) => void, errorCb = defaultErrorCb): void => {
  requestGet(`/user/purchases/shared`, successCb, errorCb);
};

/**
 * GET /admin/purchases/startDate/{startDate}/endDate/{endDate}
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const adminGetPurchaseLog = (
  startDate: string,
  endDate: string,
  successCb: (data: Purchase[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/admin/purchases/startDate/${startDate}/endDate/${endDate}`, successCb, errorCb);
};

/**
 * POST /admin/specialPurchases/startDate/{startDate}/endDate/{endDate}
 *
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const adminGetSpecialPurchases = (
  startDate: string,
  endDate: string,
  successCb: (data: SpecialPurchase[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/admin/specialPurchases/startDate/${startDate}/endDate/${endDate}`, successCb, errorCb);
};

/**
 * DELETE /purchase/{purchaseId}
 *
 * @param purchaseId Purchase ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const adminDeletePurchase = (purchaseId: string, successCb: () => void, errorCb = defaultErrorCb): void => {
  requestDelete(`/purchase/${purchaseId}`, successCb, errorCb);
};

/**
 * DELETE /specialPurchase/{specialPurchaseId}
 *
 * @param specialPurchaseId Special Purchase ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const adminDeleteSpecialPurchase = (
  specialPurchaseId: string,
  successCb: () => void,
  errorCb = defaultErrorCb,
): void => {
  requestDelete(`/specialPurchase/${specialPurchaseId}`, successCb, errorCb);
};

/**
 * POST /user/purchase
 *
 * @param email Email of user to create purchase for
 * @param purchase Purchase to POST
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const adminCreatePurchase = (
  email: string,
  purchase: POST_Purchase,
  successCb: (arg0: Purchase) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/user/purchase`, { email, purchase }, successCb, errorCb);
};

/**
 * POST /specialPurchase
 *
 * @param specialPurchase Special Purchase
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const adminCreateSpecialPurchase = (
  specialPurchase: POST_SpecialPurchase,
  successCb: (arg0: SpecialPurchase) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/specialPurchase`, specialPurchase, successCb, errorCb);
};

/**
 * GET /course/{courseId}/seats_available
 *
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getCourseSeatsAvailable = (
  courseId: string,
  successCb: (data: number) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/course/${courseId}/seats_available`, successCb, errorCb);
};

/**
 * PUT /specialPurchase/{specialPurchaseId}
 *
 * @param specialPurchaseId Special Purchase ID
 * @param specialPurchase Special Purchase
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const adminUpdateSpecialPurchase = (
  specialPurchaseId: string,
  specialPurchase: POST_SpecialPurchase,
  successCb: (arg0: SpecialPurchase) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPut(`/specialPurchase/${specialPurchaseId}`, specialPurchase, successCb, errorCb);
};

/**
 * GET /user/rubrics/self
 *
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getOwnedRubrics = (successCb: (arg0: RubricTemplate[]) => void, errorCb = defaultErrorCb): void => {
  requestGet(`/user/rubrics/self`, successCb, errorCb);
};

/**
 * GET /user/rubrics/shared
 *
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getSharedRubrics = (successCb: (arg0: RubricTemplate[]) => void, errorCb = defaultErrorCb): void => {
  requestGet(`/user/rubrics/shared`, successCb, errorCb);
};

/**
 * GET /user/peerceptiv
 *
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getPeerceptivRubrics = (successCb: (arg0: RubricTemplate[]) => void, errorCb = defaultErrorCb): void => {
  requestGet(`/rubrics/peerceptiv`, successCb, errorCb);
};

/**
 * GET /rubric/{rubricTemplateId}
 *
 * @param rubricTemplateId Rubric Template ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getRubricTemplate = (
  rubricTemplateId: string,
  successCb: (arg0: RubricTemplate) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/rubric/${rubricTemplateId}`, successCb, errorCb);
};

/**
 * PUT /rubric/{rubricTemplateId}
 *
 * @param rubricTemplateId Rubric Template ID
 * @param rubricTemplate Rubric Template to update
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const updateRubricTemplate = (
  rubricTemplateId: string,
  rubricTemplate: PUT_RubricTemplate,
  successCb: (arg0: RubricTemplate) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPut(`/rubric/${rubricTemplateId}`, rubricTemplate, successCb, errorCb);
};

/**
 * DELETE /rubric/{rubricTemplateId}
 *
 * @param rubricTemplateId Rubric Template ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const deleteRubricTemplate = (
  rubricTemplateId: string,
  successCb: () => void,
  errorCb = defaultErrorCb,
): void => {
  requestDelete(`/rubric/${rubricTemplateId}`, successCb, errorCb);
};

/**
 * POST /rubric/{rubricTemplateId}/copy
 *
 * @param rubricTemplateId Rubric Template ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const copyRubricTemplate = (
  rubricTemplateId: string,
  successCb: (arg0: RubricTemplate) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/rubric/${rubricTemplateId}/copy`, {}, successCb, errorCb);
};

/**
 * GET /user/comments/self
 *
 * @param pageQueryParams Object containing query parameters
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getUserCommentTemplates = (
  pageQueryParams: PageQueryParams,
  successCb: (arg0: PageQuery<CommentTemplate>) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/user/comments/templates?${new URLSearchParams(pageQueryParams).toString()}`, successCb, errorCb);
};

/**
 * GET /user/ratings/templates
 *
 * @param pageQueryParams Object containing query parameters
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getUserRatingTemplates = (
  pageQueryParams: PageQueryParams,
  successCb: (arg0: PageQuery<RatingTemplate>) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/user/ratings/templates?${new URLSearchParams(pageQueryParams).toString()}`, successCb, errorCb);
};

/**
 * POST /rubric/{rubricTemplateId}/comment
 *
 * @param rubricTemplateId Rubric Template ID
 * @param comment Comment
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const createRubricTemplateComment = (
  rubricTemplateId: string,
  comment: Comment,
  successCb: (arg0: Comment) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/rubric/${rubricTemplateId}/comment`, comment, successCb, errorCb);
};

/**
 * POST /rubric/{rubricTemplateId}/rating
 *
 * @param rubricTemplateId Rubric Template ID
 * @param rating Rating
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const createRubricTemplateRating = (
  rubricTemplateId: string,
  rating: Rating,
  successCb: (arg0: Rating) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/rubric/${rubricTemplateId}/rating`, rating, successCb, errorCb);
};

/**
 * POST /user/comment
 *
 * @param comment Comment
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const createUserCommentPrompt = (
  comment: Comment,
  successCb: (arg0: Comment) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/user/comment`, comment, successCb, errorCb);
};

/**
 * POST /user/rating
 *
 * @param rating Rating
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const createUserRatingPrompt = (
  rating: Rating,
  successCb: (arg0: Rating) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/user/rating`, rating, successCb, errorCb);
};

/**
 * POST /rubric/{rubricTemplateId}/comment/{commentId}/copy
 *
 * @param rubricTemplateId Rubric Template ID
 * @param commentId Comment ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const copyCommentIntoRubricTemplate = (
  rubricTemplateId: string,
  commentId: string,
  successCb: (arg0: Rating) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/rubric/${rubricTemplateId}/comment/${commentId}/copy`, {}, successCb, errorCb);
};

/**
 * POST /rubric/{rubricTemplateId}/rating/{ratingId}/copy
 *
 * @param rubricTemplateId Rubric Template ID
 * @param ratingId Rating ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const copyRatingIntoRubricTemplate = (
  rubricTemplateId: string,
  ratingId: string,
  successCb: (arg0: Rating) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/rubric/${rubricTemplateId}/rating/${ratingId}/copy`, {}, successCb, errorCb);
};

/**
 * POST /rubric
 *
 * @param rubricPostData Rubric Template POST data
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const createRubricTemplate = (
  rubricPostData: POST_RubricTemplate,
  successCb: (arg0: RubricTemplate) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/rubric`, rubricPostData, successCb, errorCb);
};

/**
 * GET /assignment/{assignmentId}/rubric
 *
 * @param assignmentId Assignment Id
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getSubmissionRubric = (
  assignmentId: string,
  successCb: (arg0: AssignmentRubric) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/${assignmentId}/rubric`, successCb, errorCb);
};

/**
 * GET /assignment/{assignmentId}/eval/{target}/rubric
 *
 * @param assignmentId Assignment ID
 * @param target Rubric Evaluation Target
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getPeerEvalRubric = (
  assignmentId: string,
  target: EvaluationTarget,
  successCb: (arg0: AssignmentRubric | null) => void,
): void => {
  requestGet(`/assignment/${assignmentId}/eval/${target}/rubric`, successCb, (err) => {
    if (err.response?.status === 404) {
      successCb(null);
      return true;
    }
    return false;
  });
};

/**
 * PUT /assignment/{assignmentId}/rubric
 *
 * @param assignmentId Assignment ID
 * @param rubric Assignment Rubric
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const updateAssignmentRubric = (
  assignmentId: string,
  rubric: PUT_AssignmentRubric,
  successCb: (arg0: AssignmentRubric) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPut(`/assignment/${assignmentId}/rubric`, rubric, successCb, errorCb);
};

/**
 * PUT /assignment/{assignmentId}/eval/{target}/rubric
 *
 * @param assignmentId Assignment ID
 * @param target Evaluation Target
 * @param rubric Assignment Rubric
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const updateEvalAssignmentRubric = (
  assignmentId: string,
  target: EvaluationTarget,
  rubric: PUT_AssignmentRubric,
  successCb: (arg0: AssignmentRubric) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPut(`/assignment/${assignmentId}/eval/${target}/rubric`, rubric, successCb, errorCb);
};

/**
 * POST /assignment/{assignmentId}/comment/{commentId}/copy
 *
 * @param assignmentId Assignment ID
 * @param commentId Comment ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const copyCommentIntoAssignmentRubric = (
  assignmentId: string,
  commentId: string,
  successCb: (arg0: AssignmentRubric) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/assignment/${assignmentId}/comment/${commentId}/copy`, {}, successCb, errorCb);
};

/**
 * POST /assignment/{assignmentId}/eval/{target}/comment/{commentId}/copy
 *
 * @param assignmentId Assignment ID
 * @param target Eval Target
 * @param commentId Comment ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const copyCommentIntoAssignmentEvalRubric = (
  assignmentId: string,
  target: EvaluationTarget,
  commentId: string,
  successCb: (arg0: AssignmentRubric) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/assignment/${assignmentId}/eval/${target}/comment/${commentId}/copy`, {}, successCb, errorCb);
};

/**
 * POST /assignment/{assignmentId}/rating/{ratingId}/copy
 *
 * @param assignmentId Assignment ID
 * @param ratingId Rating ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const copyRatingIntoAssignmentRubric = (
  assignmentId: string,
  ratingId: string,
  successCb: (arg0: AssignmentRubric) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/assignment/${assignmentId}/rating/${ratingId}/copy`, {}, successCb, errorCb);
};

/**
 * POST /assignment/{assignmentId}/eval/{target}/rating/{ratingId}/copy
 *
 * @param assignmentId Assignment ID
 * @param target Eval Target
 * @param ratingId Rating ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const copyRatingIntoAssignmentEvalRubric = (
  assignmentId: string,
  target: EvaluationTarget,
  ratingId: string,
  successCb: (arg0: AssignmentRubric) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/assignment/${assignmentId}/eval/${target}/rating/${ratingId}/copy`, {}, successCb, errorCb);
};

/**
 * POST /assignment/{assignmentId}/target/{target}/rubric/{rubricTemplateId}/import
 *
 * @param assignmentId Assignment ID
 * @param target Rubric Target
 * @param rubricTemplateId Rubric Template ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const importRubricTemplateToAssignmentRubric = (
  assignmentId: string,
  target: RubricTarget,
  rubricTemplateId: string,
  successCb: (arg0: AssignmentRubric) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/assignment/${assignmentId}/target/${target}/rubric/${rubricTemplateId}/import`, {}, successCb, errorCb);
};

/**
 * POST /rubric/{rubricTemplateId}/sharing/user
 *
 * @param rubricTemplateId Rubric Template ID
 * @param email User's email to share rubric template with
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const shareRubricTemplate = (
  rubricTemplateId: string,
  email: string,
  successCb: (arg0: RubricTemplate) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/rubric/${rubricTemplateId}/sharing/user`, { email }, successCb, errorCb);
};

/**
 * DELETE /rubric/{rubricTemplateId}/sharing/{sharedRubricId}
 *
 * @param rubricTemplateId Rubric Template ID
 * @param sharedRubricId Shared Rubric ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const unshareRubricTemplate = (
  rubricTemplateId: string,
  sharedRubricId: string,
  successCb: (arg0: RubricTemplate) => void,
  errorCb = defaultErrorCb,
): void => {
  requestDelete(`/rubric/${rubricTemplateId}/sharing/${sharedRubricId}`, successCb, errorCb);
};

/**
 * GET /assignment/{assignmentId}/groups/sync/categories
 *
 * @param assignmentId Assignment ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getGroupSyncableCategoryOptions = (
  assignmentId: string,
  successCb: (arg0: GroupSyncCategory[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/${assignmentId}/groups/sync/categories`, successCb, errorCb);
};

/**
 * POST /assignment/{assignmentId}/groups/category/{categoryId}/sync
 *
 * @param assignmentId Assignment ID
 * @param categoryId Group Sync Category ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const groupSync = (
  assignmentId: string,
  categoryId: string,
  successCb: (arg0: Group[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/assignment/${assignmentId}/groups/category/${categoryId}/sync`, {}, successCb, errorCb);
};

/**
 * GET /admin/courses
 *
 * @param limit Number of courses per page
 * @param page Page number to return
 * @param active Whether to only return active courses
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const adminGetCourses = (
  limit: number,
  page: number,
  active: boolean,
  successCb: (arg0: PageQuery<Course>) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/admin/courses?limit=${limit}&page=${page}&active=${active}`, successCb, errorCb);
};

/**
 * GET /admin/users
 *
 * @param limit Number of users per page
 * @param page Page number to return
 * @param email Email of user to search for
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const adminGetUsers = (
  limit: number,
  page: number,
  email: string,
  successCb: (arg0: PageQuery<User>) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/admin/users?limit=${limit}&page=${page}${email !== '' ? `&search=${email}` : ''}`, successCb, errorCb);
};

/**
 * GET /assignment/default_workflows
 *
 * @param asyncEnabled Boolean value representing whether the current course is async
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getStandardWorkflows = (
  asyncEnabled: boolean,
  successCb: (arg0: Assignment[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/default_workflows?async=${asyncEnabled}`, successCb, errorCb);
};

/**
 * GET /course/{courseId}/me
 *
 * @param courseId Course ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getMyCourseUser = (courseId: string, successCb: (arg0: User) => void, errorCb = defaultErrorCb): void => {
  requestGet(`/course/${courseId}/me`, successCb, errorCb);
};

/**
 * GET /results/assignment/{assignmentId}/details/instructor_submission
 *
 * @param assignmentId Assignment ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getInstructorUploadResults = (
  assignmentId: string,
  successCb: (arg0: AssignmentDetailedResults[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/results/assignment/${assignmentId}/details/instructor_submission`, successCb, errorCb);
};

/**
 * POST /submission/{submissionId}/report
 *
 * @param submissionId Submission ID
 * @param report Report object
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const reportSubmission = (
  submissionId: string,
  report: POST_Report,
  successCb: (arg0: POST_Report) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/submission/${submissionId}/report`, report, successCb, errorCb);
};

/**
 * POST /review/{reviewId}/report
 *
 * @param reviewId
 * @param report
 * @param successCb
 * @param errorCb
 */
export const reportReview = (
  reviewId: string,
  report: POST_Report,
  successCb: (arg0: POST_Report) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/review/${reviewId}/report`, report, successCb, errorCb);
};

/**
 * GET /assignment/{assignmentId}/reports
 *
 * @param assignmentId Assignment ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getAssignmentReports = (
  assignmentId: string,
  successCb: (arg0: Report[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/${assignmentId}/reports`, successCb, errorCb);
};

/**
 * GET /report/{reportId}
 *
 * @param reportId Report ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getReport = (reportId: string, successCb: (arg0: Report) => void, errorCb = defaultErrorCb): void => {
  requestGet(`/report/${reportId}`, successCb, errorCb);
};

/**
 * PUT /report/{reportId}/approve
 *
 * @param reportId Report ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const approveReport = (reportId: string, successCb: (arg0: Report) => void, errorCb = defaultErrorCb): void => {
  requestPut(`/report/${reportId}/approve`, {}, successCb, errorCb);
};

/**
 * PUT /report/{reportId}/reject
 *
 * @param reportId Report ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const rejectReport = (reportId: string, successCb: (arg0: Report) => void, errorCb = defaultErrorCb): void => {
  requestPut(`/report/${reportId}/reject`, {}, successCb, errorCb);
};

/**
 * GET /assignment/{assignmentId}/user/{userId}/submission/history
 *
 * @param assignmentId Assignment ID
 * @param userId User ID
 * @param successCb Callback success
 * @param errorCb Callback error
 */
export const getUserSubmissionHistory = (
  assignmentId: string,
  userId: string,
  successCb: (arg0: SubmissionInfo[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/${assignmentId}/user/${userId}/submission/history`, successCb, errorCb);
};

/**
 * DELETE /eval/{peerEvaluationId}
 *
 * @param peerEvaluationId Team Member Evaluation ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const deleteEval = (peerEvaluationId: string, successCb: () => void, errorCb = defaultErrorCb): void => {
  requestDelete(`/eval/${peerEvaluationId}`, successCb, errorCb);
};

/**
 * POST /admin/user/{userId}/paid
 *
 * @param userId User ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const adminMarkPaid = (userId: string, successCb: (arg0: Group[]) => void, errorCb = defaultErrorCb): void => {
  requestPost(`/admin/user/${userId}/paid`, {}, successCb, errorCb);
};

/**
 * POST: /assignment/{assignmentId}/results/restart
 *
 * @param assignmentId Assignment ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const asyncReset = (assignmentId: string, successCb: () => void, errorCb = defaultErrorCb): void => {
  requestPost(`/assignment/${assignmentId}/results/restart`, {}, successCb, errorCb);
};

/**
 * GET: /assignment/{assignmentId}/results/map
 *
 * @param assignmentId Assignment ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getStudentResultsMap = (
  assignmentId: string,
  successCb: (arg0: { [index: string]: Result }) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/${assignmentId}/results/map`, successCb, errorCb);
};

/**
 * GET: /notifications
 *
 * @param limit Limit on number of results
 * @param page Page number
 * @param seen Notification-seen status to filter by (or null if no filtering)
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getNotifications = (
  limit: number,
  page: number,
  seen: boolean | null,
  successCb: (arg0: PageQuery<Notification>) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/notifications?limit=${limit}&page=${page}${seen !== null ? `&seen=${seen}` : ''}`, successCb, errorCb);
};

/**
 * POST: /notifications/seen
 *
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const markAllNotificationsAsRead = (
  successCb: (arg0: Notification[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/notifications/seen`, {}, successCb, errorCb);
};

/**
 * PUT: /notifications/{notificationId}/seen
 *
 * @param notificationId Notification ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const markNotificationRead = (
  notificationId: string,
  successCb: (arg0: Notification) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPut(`/notification/${notificationId}/seen`, {}, successCb, errorCb);
};

/**
 * DELETE: /notification/{notificationId}
 *
 * @param notificationId Notification ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const deleteNotification = (notificationId: string, successCb: () => void, errorCb = defaultErrorCb): void => {
  requestDelete(`/notification/${notificationId}`, successCb, errorCb);
};

/**
 * GET: /notifications/metadata
 *
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getNotificationsMetaData = (
  successCb: (arg0: NotificationMetaData) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/notifications/metadata`, successCb, errorCb);
};

/**
 * GET: /notifications/action/{notificationActionId}
 *
 * @param notificationActionId Notification Action ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getNotificationAction = (
  notificationActionId: string,
  successCb: (arg0: NotificationAction) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/notifications/action/${notificationActionId}`, successCb, errorCb);
};

/**
 * GET /course/{courseId}/events/daily
 *
 * @param courseId Course ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getCourseDailyTaskEvents = (
  courseId: string,
  successCb: (arg0: DailyTaskEvents[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/course/${courseId}/events/daily`, successCb, errorCb);
};

/**
 * GET /course/{courseId}/activity_stats
 *
 * @param courseId Course ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getCourseActivityStats = (
  courseId: string,
  successCb: (arg0: ActivityStats) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/course/${courseId}/activity_stats`, successCb, errorCb);
};

/**
 * GET /school_admin/users
 *
 * @param pageQueryParams Object containing query parameters
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const schoolAdminGetUsers = (
  pageQueryParams: PageQueryParams,
  successCb: (arg0: PageQuery<User>) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/school_admin/users?${new URLSearchParams(pageQueryParams).toString()}`, successCb, errorCb);
};

/**
 * GET /user/{userId}/rating/averages
 *
 * @param userId User ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getUserRatingAverages = (
  userId: string,
  successCb: (arg0: AverageRating[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/user/${userId}/rating/averages`, successCb, errorCb);
};

/**
 * GET /user/{userId}/tag/averages
 *
 * @param userId User ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getUserTagAverages = (
  userId: string,
  successCb: (arg0: AverageRating[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/user/${userId}/tag/averages`, successCb, errorCb);
};

/**
 * GET /user/{userId}/events/daily
 *
 * @param userId User ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getUserDailyActivity = (
  userId: string,
  successCb: (arg0: DailyTaskEvents[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/user/${userId}/events/daily`, successCb, errorCb);
};

/**
 * GET /user/{userId}/rating/{ratingId}/averages
 *
 * @param userId User ID
 * @param ratingId Rating ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getUserAveragesOvertimeForRating = (
  userId: string,
  ratingId: string,
  successCb: (arg0: AverageRating[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/user/${userId}/rating/${ratingId}/averages`, successCb, errorCb);
};

/**
 * GET /user/{userId}/tag/{tagId}/averages
 *
 * @param userId User ID
 * @param tagId Tag ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getUserAveragesOvertimeForTag = (
  userId: string,
  tagId: string,
  successCb: (arg0: AverageRating[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/user/${userId}/tag/${tagId}/averages`, successCb, errorCb);
};

/**
 * GET /tags
 *
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getAvailableTags = (successCb: (arg0: Tag[]) => void, errorCb = defaultErrorCb): void => {
  requestGet(`/tags`, successCb, errorCb);
};

/**
 * POST /tag
 *
 * @param content Tag content
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const createTag = (content: string, successCb: (arg0: Tag) => void, errorCb = defaultErrorCb): void => {
  requestPost(`/tag`, { content }, successCb, errorCb);
};

/**
 * POST /assignment/{assignmentId}/review/assign
 *
 * @param assignmentId Assignment ID
 * @param groups Groups organized as a 2D array
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const assignReviews = (
  assignmentId: string,
  groups: string[][],
  successCb: (arg0: ReviewUsers[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/assignment/${assignmentId}/review/assign`, { groups }, successCb, errorCb);
};

/**
 * GET /admin/tags/averages
 *
 * @param pageQueryParams Object containing query parameters
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const adminGetTagAverages = (
  pageQueryParams: PageQueryParams,
  successCb: (arg0: PageQuery<AverageRating>) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/admin/tags/averages?${new URLSearchParams(pageQueryParams).toString()}`, successCb, errorCb);
};

/**
 * GET /assignment/{assignmentId}/review/users
 *
 * @param assignmentId Assignment ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getReviewUsers = (
  assignmentId: string,
  successCb: (arg0: ReviewUsers[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/${assignmentId}/review/users`, successCb, errorCb);
};

/**
 * GET /instructor/{userId}/tag/averages
 *
 * @param userId User ID
 * @param pageQueryParams Object containing query parameters
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getInstructorTagAverages = (
  userId: string,
  pageQueryParams: PageQueryParams,
  successCb: (arg0: PageQuery<AverageRating>) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(
    `/instructor/${userId}/tag/averages?${new URLSearchParams(pageQueryParams).toString()}`,
    successCb,
    errorCb,
  );
};

/**
 * GET /instructor/{userId}/rating/averages
 *
 * @param userId User ID
 * @param pageQueryParams Object containing query parameters
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getInstructorRatingAverages = (
  userId: string,
  pageQueryParams: PageQueryParams,
  successCb: (arg0: PageQuery<AverageRating>) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(
    `/instructor/${userId}/rating/averages?${new URLSearchParams(pageQueryParams).toString()}`,
    successCb,
    errorCb,
  );
};

/**
 * GET /course/templates
 *
 * @param pageQueryParams Object containing query parameters
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getCourseTemplates = (
  pageQueryParams: PageQueryParams,
  successCb: (arg0: PageQuery<Course>) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/course/templates?${new URLSearchParams(pageQueryParams).toString()}`, successCb, errorCb);
};

/**
 * POST /course/template
 *
 * @param courseTemplate Course Template data
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const createCourseTemplate = (
  courseTemplate: Course,
  successCb: (arg0: Course) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/course/template`, courseTemplate, successCb, errorCb);
};

/**
 * GET /assignment/templates
 *
 * @param pageQueryParams Object containing query parameters
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getAssignmentTemplates = (
  pageQueryParams: PageQueryParams,
  successCb: (arg0: PageQuery<Assignment>) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/templates?${new URLSearchParams(pageQueryParams).toString()}`, successCb, errorCb);
};

/**
 * GET /assignments
 *
 * @param pageQueryParams Object containing query parameters
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const adminGetAssignments = (
  pageQueryParams: PageQueryParams,
  successCb: (arg0: PageQuery<Assignment>) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignments?${new URLSearchParams(pageQueryParams).toString()}`, successCb, errorCb);
};

/**
 * GET /user/rubrics/templates
 *
 * @param pageQueryParams Object containing query parameters
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getUserRubricTemplates = (
  pageQueryParams: PageQueryParams,
  successCb: (arg0: PageQuery<RubricTemplate>) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/user/rubrics/templates?${new URLSearchParams(pageQueryParams).toString()}`, successCb, errorCb);
};

/**
 * GET /tag/{tagId}
 *
 * @param tagId Tag ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getTag = (tagId: string, successCb: (arg0: Tag) => void, errorCb = defaultErrorCb): void => {
  requestGet(`/tag/${tagId}`, successCb, errorCb);
};

/**
 * GET /tag/{tagId}
 *
 * @param tagId Tag ID
 * @param tag Updated tag data
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const updateTag = (tagId: string, tag: Tag, successCb: (arg0: Tag) => void, errorCb = defaultErrorCb): void => {
  requestPut(`/tag/${tagId}`, tag, successCb, errorCb);
};

/**
 * GET /course/copyable
 *
 * @param pageQueryParams Object containing query parameters
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getCopyableCourses = (
  pageQueryParams: PageQueryParams,
  successCb: (arg0: PageQuery<Course>) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/course/copyable?${new URLSearchParams(pageQueryParams).toString()}`, successCb, errorCb);
};

/**
 * GET /course/dashboard/stats
 *
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getCourseDashboardStats = (
  successCb: (arg0: CourseDashboardStats) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/course/dashboard/stats`, successCb, errorCb);
};

/**
 * GET /assignment/dashboard/stats
 *
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getAssignmentDashboardStats = (
  successCb: (arg0: AssignmentDashboardStats) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/dashboard/stats`, successCb, errorCb);
};

/**
 * GET /rubric/dashboard/stats
 *
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getRubricDashboardStats = (
  successCb: (arg0: RubricDashboardStats) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/rubric/dashboard/stats`, successCb, errorCb);
};

/**
 * GET /user/dashboard/stats
 *
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getUserDashboardStats = (
  successCb: (arg0: UserDashboardStats) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/user/dashboard/stats`, successCb, errorCb);
};

/**
 * GET /tag/{tagId}/rating/averages
 *
 * @param tagId Tag ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getTagRatingAverages = (
  tagId: string,
  successCb: (arg0: AverageRating[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/tag/${tagId}/rating/averages`, successCb, errorCb);
};

/**
 * POST /course/{courseId}/copy/template
 *
 * @param courseId Course ID of course to copy
 * @param successCb Callback for success
 * @param errorCb Callback for success
 */
export const copyCourseAsTemplate = (
  courseId: string,
  successCb: (arg0: Course) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/course/${courseId}/copy/template`, {}, successCb, errorCb);
};

/**
 * POST /assignment/{assignmentId}/generated_check
 *
 * @param assignmentId
 * @param successCb
 * @param errorCb
 */
export const runGPTZeroScan = (
  assignmentId: string,
  successCb: (arg0: Assignment) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/assignment/${assignmentId}/generated_check`, {}, successCb, errorCb);
};

/**
 * POST /assignment/{assignmentId}/submission/{submissionId}/deanonymize
 *
 * @param assignmentId Assignment ID
 * @param assignmentId User ID
 * @param successCb Callback success
 * @param errorCb Callback error
 */
export const deanonymize = (
  assignmentId: string | undefined,
  submissionId: string | null,
  successCb: () => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/assignment/${assignmentId}/submission/${submissionId}/deanonymize`, {}, successCb, errorCb);
};

/**
 * GET: /assignment/{assignmentId}/deanonymized
 *
 * @param assignmentId Assignment ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getDeanonymizedList = (
  assignmentId: string,
  successCb: (arg0: string[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/${assignmentId}/deanonymized`, successCb, errorCb);
};

/**
 * GET: /institutions
 *
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getInstitutions = (successCb: (arg0: string[]) => void, errorCb = defaultErrorCb): void => {
  requestGet(`/institutions`, successCb, errorCb);
};

/**
 * GET /assignment/{assignmentId}/rubrics/target_map
 *
 * @param assignmentId Assignment ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */

export const getRubricsMap = (
  assignmentId: string,
  successCb: (arg0: RubricsTargetMap) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/${assignmentId}/rubrics/target_map`, successCb, errorCb);
};

/**
 * POST admin/codes/generate
 *
 * @param studentPurchase studentPurchaseInfo
 * @param successCb Callback success
 * @param errorCb Callback error
 */
export const generateStudentPurchaseCode = (
  formData: FormData,
  successCb: (arg0: string[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/admin/codes/generate`, formData, successCb, errorCb);
};

/**
 * POST /assignment/{assignmentId}/groups/open
 *
 * @param assignmentId Assignment ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const openAllAssignmentGroups = (
  assignmentId: string,
  successCb: (arg0: Group[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/assignment/${assignmentId}/groups/open`, {}, successCb, errorCb);
};

/**
 * POST /assignment/{assignmentId}/groups/close
 *
 * @param assignmentId Assignment ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const closeAllAssignmentGroups = (
  assignmentId: string,
  successCb: (arg0: Group[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/assignment/${assignmentId}/groups/close`, {}, successCb, errorCb);
};

/**
 * POST /assignment/{assignmentId}/groups/{groupId}/open
 *
 * @param assignmentId Assignment ID
 * @param groupId Group ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const openAssignmentGroup = (
  assignmentId: string,
  groupId: string,
  successCb: (arg0: Group) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/assignment/${assignmentId}/groups/${groupId}/open`, {}, successCb, errorCb);
};

/**
 * POST /assignment/{assignmentId}/groups/{groupId}/close
 *
 * @param assignmentId Assignment ID
 * @param groupId Group ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const closeAssignmentGroup = (
  assignmentId: string,
  groupId: string,
  successCb: (arg0: Group) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/assignment/${assignmentId}/groups/${groupId}/close`, {}, successCb, errorCb);
};

/**
 * POST /assignment/{assignmentId}/review/assign/unique_group_members
 *
 * @param assignmentId Assignment ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const assignUniqueReviewsForGroupMembers = (
  assignmentId: string,
  successCb: (arg0: ReviewUsers[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/assignment/${assignmentId}/review/assign/unique_group_members`, {}, successCb, errorCb);
};

/**
 * POST /assignment/{assignmentId}/review/users/copy/{sourceAssignmentId}
 *
 * @param assignmentId Assignment ID
 * @param sourceAssignmentId Source Assignment ID
 * @param successCb Callback success
 * @param errorCb Callback error
 */
export const copyAssignReviews = (
  assignmentId: string,
  sourceAssignmentId: string,
  successCb: (arg0: ReviewUsers[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/assignment/${assignmentId}/review/users/copy/${sourceAssignmentId}`, {}, successCb, errorCb);
};

/**
 * DELETE /assignment/{assignmentId}/reviews
 *
 * @param assignmentId Assignment ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const deleteAssignmentReviews = (
  assignmentId: string,
  successCb: () => void,
  errorCb = defaultErrorCb,
): void => {
  requestDelete(`/assignment/${assignmentId}/reviews`, successCb, errorCb);
};

/**
 * DELETE /assignment/{assignmentId}/reviews/submitter/{submitterId}
 *
 * @param assignmentId Assignment ID
 * @param submitterId Submitter ID
 * @param reviewerIds User IDs of reviewers whose reviews to delete
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const deleteReviewsOfUsersInAssignment = (
  assignmentId: string,
  submitterId: string,
  reviewerIds: string[],
  successCb: () => void,
  errorCb = defaultErrorCb,
): void => {
  requestDelete(`/assignment/${assignmentId}/reviews/submitter/${submitterId}`, successCb, errorCb, {
    data: reviewerIds,
  });
};

/**
 * GET /assignment/{assignmentId}/reflection
 *
 * @param {number} assignmentId ID of assignment
 * @param {function(array): *} commentsCb Callback for comments
 */
export const getReflection = (
  assignmentId: string,
  successCb: (arg0: Reflection) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/${assignmentId}/reflection`, successCb, errorCb);
};

/**
 * POST /assignment/{assignmentId}/reflection
 *
 * @param {*} assignmentId assignmentId to post
 * @param {*} successCb Callback for successful POST
 * @param {*} errorCb Callback for error
 */
export const newReflection = (
  assignmentId: string,
  successCb: (reflection: Reflection) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/assignment/${assignmentId}/reflection`, {}, successCb, errorCb);
};

/**
 * GET /admin/statics/startDate/{startDate}/endDate/{endDate}
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getAdminStatics = (
  startDate: string,
  endDate: string,
  successCb: (data: AdminStatistics) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/admin/statics/startDate/${startDate}/endDate/${endDate}`, successCb, errorCb);
};

/**
 * PUT /reflection/{reflectionId}
 *
 * @param {*} reviewComments reflection comment to post
 * @param {*} assignmentId assignmentId to post
 * @param {*} successCb Callback for successful POST
 * @param {*} errorCb Callback for error
 */
export const postReflection = (
  reviewComments: CommentData[],
  assignmentId: string,
  reflectionId: string,
  successCb: (reflection: Reflection) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPut(`/reflection/${reflectionId}`, { reflectionId, assignmentId, reviewComments }, successCb, errorCb);
};

/**
 * POST /assignment/{assignmentId}/reflection/comment
 *
 * @param {*} assignmentId ID of rubric's assignment to add to
 * @param {*} order The order of the new comment prompt
 * @param {*} commentData Comment data
 * @param {*} dataCb Callback for the response data
 * @param {*} errorCb Callback for error
 */
export const postReflectionCommentNew = (
  assignmentId: string,
  order: number,
  commentData: Comment,
  successCb: (arg0: Comment) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(
    `/assignment/${assignmentId}/reflection/comment`,
    {
      ...commentData,
      assignmentId,
      order,
    },
    successCb,
    errorCb,
  );
};

/**
 * POST /assignment/{assignmentId}/reflection/comment/{commentId}/copy
 *
 * @param assignmentId Assignment ID
 * @param commentId Comment ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const copyCommentIntoReflectionEvalRubric = (
  assignmentId: string,
  commentId: string,
  successCb: (arg0: AssignmentRubric) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/assignment/${assignmentId}/reflection/comment/${commentId}/copy`, {}, successCb, errorCb);
};

/**
 * GET /assignment/{assignmentId}/reviewComment/sorted
 *
 * @param {number} assignmentId ID of assignment
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getSortedReviewComments = (
  assignmentId: string,
  successCb: (arg0: ReviewCommentWithName[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/${assignmentId}/reviewComment/sorted`, successCb, errorCb);
};

/**
 * GET /school_admin/courses
 *
 * @param pageQueryParams Object containing query parameters
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const schoolAdminGetCourses = (
  pageQueryParams: PageQueryParams,
  successCb: (arg0: PageQuery<Course>) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/school_admin/courses?${new URLSearchParams(pageQueryParams).toString()}`, successCb, errorCb);
};

/**
 * GET /institutionStats/startDate/{startDate}/endDate/{endDate}
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getInstitutionStats = (
  startDate: string,
  endDate: string,
  successCb: (data: InstitutionStats[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/institutionStats/startDate/${startDate}/endDate/${endDate}`, successCb, errorCb);
};

/* POST /course/{courseId}/email/{email}/invite
 *
 * @param courseId Course ID
 * @param email email
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const sendCourseTemplateViaEmail = (
  courseId: string,
  email: string,
  successCb: (arg0: boolean) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/course/${courseId}/email/${email}/invite`, {}, successCb, errorCb);
};

/**
 * POST /course/{courseId}/template/publish
 *
 * @param courseId Course ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const publishCourseTemplate = (
  courseId: string,
  successCb: (arg0: boolean) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/course/${courseId}/template/publish`, {}, successCb, errorCb);
};

/**
 * GET /rating/{ratingId}/rating_score
 *
 * @param {number} ratingId ID of rating
 */
export const getAverageRatingScoreForAdmin = (
  ratingId: string,
  successCb: (arg0: RatingScore) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/rating/${ratingId}/rating_score`, successCb, errorCb);
};

/**
 * GET /user/{userId}/instructor/courseInfo
 * @param userId User ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getInstructorCourse = (
  userId: string,
  successCb: (arg0: InstructorCourse[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/user/${userId}/instructor/courseInfo`, successCb, errorCb);
};

/**
 * GET /course/ratings
 *
 * @param pageQueryParams Object containing query parameters
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getCourseRating = (
  pageQueryParams: PageQueryParams,
  successCb: (arg0: PageQuery<CourseRating>) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/course/ratings?${new URLSearchParams(pageQueryParams).toString()}`, successCb, errorCb);
};

/**
 * GET /course/{courseId}/rating/average
 *
 * @param pageQueryParams Object containing query parameters
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getCourseRatingAverage = (
  courseId: string,
  successCb: (arg0: CourseRatingAverage[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/course/${courseId}/rating/average`, successCb, errorCb);
};

/**
 * GET /course/{courseId}/tag/averages
 *
 * @param courseId Course ID
 * @param pageQueryParams Object containing query parameters
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getCourseTagAverages = (
  courseId: string,
  pageQueryParams: PageQueryParams,
  successCb: (arg0: PageQuery<AverageRating>) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/course/${courseId}/tag/averages?${new URLSearchParams(pageQueryParams).toString()}`, successCb, errorCb);
};

/**
 * GET /course/{courseId}/rating/{ratingId}/rating_score
 * @param {number} ratingId ID of rating
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getAverageRatingScoreForCourse = (
  courseId: string,
  ratingId: string,
  successCb: (arg0: RatingScore) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/course/${courseId}/rating/${ratingId}/rating_score`, successCb, errorCb);
};

/**
 * GET /assignment/{assignmentId}/user/{userId}/reflection
 * @param assignmentId Assignment ID
 * @param userId User ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */

export const getReflectionReviewComment = (
  assignmentId: string,
  userId: string,
  successCb: (arg0: ReviewCommentWithName[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/${assignmentId}/user/${userId}/reflection`, successCb, errorCb);
};

/**
 * GET /assignment/{assignmentId}/user/{userId}/reset_review/given/items
 *
 * @param userId User ID
 * @param assignmentId Assignment ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getResetReviewsByUserInAssignment = (
  userId: string,
  assignmentId: string,
  successCb: (arg0: ReviewByItem[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/${assignmentId}/user/${userId}/reset_review/given/items`, successCb, errorCb);
};

/**
 * GET /assignment/{assignmentId}/user/{userId}/reset_review/given/items
 *
 * @param userId User ID
 * @param assignmentId Assignment ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getResetReviewsForUserInAssignment = (
  userId: string,
  assignmentId: string,
  successCb: (arg0: ReviewByItem[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/${assignmentId}/user/${userId}/reset_review/received/items`, successCb, errorCb);
};

/**
 * GET /assignment/{assignmentId}/groups/map
 *
 * @param assignmentId Assignment ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getGroupMembershipMap = (
  assignmentId: string,
  successCb: (arg0: { [index: string]: Group }) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/${assignmentId}/groups/map`, successCb, errorCb);
};

/**
 * POST /assignment/{assignmentId}/groups/group_formation/survey/response
 *
 * @param assignmentId Assignment ID
 * @param data Group Formation data
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const saveGroupFormationResponse = (
  assignmentId: string,
  data: number[],
  successCb: (arg0: GroupFormationResponse) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/assignment/${assignmentId}/groups/group_formation/survey/response`, data, successCb, errorCb);
};

/**
 * GET /assignment/{assignmentId}/groups/group_formation/survey/response
 *
 * @param assignmentId Assignment ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getGroupFormationResponse = (
  assignmentId: string,
  successCb: (arg0: GroupFormationResponse | null) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/${assignmentId}/groups/group_formation/survey/response`, successCb, errorCb);
};

/**
 * GET /assignment/{assignmentId}/user/{userId}/groups/group_formation/survey/response
 *
 * @param assignmentId Assignment ID
 * @param userId User ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getStudentGroupFormationResponse = (
  assignmentId: string,
  userId: string,
  successCb: (arg0: GroupFormationResponse | null) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/${assignmentId}/user/${userId}/groups/group_formation/survey/response`, successCb, errorCb);
};

/**
 * POST /assignment/{assignmentId}/groups/group_formation/run
 *
 * @param assignmentId Assignment ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const runGroupFormation = (
  assignmentId: string,
  successCb: (arg0: number[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/assignment/${assignmentId}/groups/group_formation/run`, { k: -1, data: [] }, successCb, errorCb);
};

/**
 * GET /assignment/{assignmentId}/groups/group_formation/survey
 *
 * @param assignmentId Assignment ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getGroupFormationSurvey = (
  assignmentId: string,
  successCb: (arg0: GroupFormationSurvey | null) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/${assignmentId}/groups/group_formation/survey`, successCb, errorCb);
};

/**
 * POST /assignment/{assignmentId}/groups/group_formation/survey
 *
 * @param assignmentId Assignment ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const createGroupFormationSurvey = (
  assignmentId: string,
  successCb: (arg0: GroupFormationSurvey) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/assignment/${assignmentId}/groups/group_formation/survey`, {}, successCb, errorCb);
};

/**
 * PUT /assignment/{assignmentId}/groups/group_formation/survey
 *
 * @param assignmentId Assignment ID
 * @param surveyData Group Formation Survey Data
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const updateGroupFormationSurvey = (
  assignmentId: string,
  surveyData: GroupFormationSurvey,
  successCb: (arg0: GroupFormationSurvey) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPut(`/assignment/${assignmentId}/groups/group_formation/survey`, surveyData, successCb, errorCb);
};

/**
 * POST /assignment/{assignmentId}/groups/group_formation/survey/prompt
 *
 * @param assignmentId Assignment ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const createGroupFormationSurveyPrompt = (
  assignmentId: string,
  promptData: GroupFormationSurveyPrompt,
  successCb: (arg0: GroupFormationSurveyPrompt) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/assignment/${assignmentId}/groups/group_formation/survey/prompt`, promptData, successCb, errorCb);
};

/**
 * PUT /group_formation/survey/prompt/{surveyPromptId}
 *
 * @param surveyPromptId Survey Prompt ID
 * @param promptData Group Formation Survey Prompt Data
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const updateGroupFormationSurveyPrompt = (
  surveyPromptId: string,
  promptData: GroupFormationSurveyPrompt,
  successCb: (arg0: GroupFormationSurveyPrompt) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPut(`/group_formation/survey/prompt/${surveyPromptId}`, promptData, successCb, errorCb);
};

/**
 * DELETE /group_formation/survey/prompt/{surveyPromptId}
 *
 * @param surveyPromptId Survey Prompt ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const deleteGroupFormationSurveyPrompt = (
  surveyPromptId: string,
  successCb: () => void,
  errorCb = defaultErrorCb,
): void => {
  requestDelete(`/group_formation/survey/prompt/${surveyPromptId}`, successCb, errorCb);
};

/**
 * GET /assignment/{assignmentId}/results/user/comments/results
 *
 * @param assignmentId Assignment ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getReviewCommentsWithResultsData = (
  assignmentId: string,
  successCb: (arg0: ReviewCommentWithResultData[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/${assignmentId}/results/user/comments/results`, successCb, errorCb);
};

/**
 * GET /assignment/{assignmentId}/results/eval/{target}/user/rating_scores
 *
 * @param assignmentId Assignment ID
 * @param target Evaluation Target
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getStudentEvalRatingScores = (
  assignmentId: string,
  target: EvaluationTarget,
  successCb: (arg0: UserRatingScore[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/${assignmentId}/results/eval/${target}/user/rating_scores`, successCb, errorCb);
};

/**
 * GET /assignment/{assignmentId}/results/eval/{target}/user/comments/results
 *
 * @param assignmentId Assignment ID
 * @param target Evaluation Target
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getEvalCommentsWithResultsData = (
  assignmentId: string,
  target: EvaluationTarget,
  successCb: (arg0: ReviewCommentWithResultData[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/assignment/${assignmentId}/results/eval/${target}/user/comments/results`, successCb, errorCb);
};

/**
 * GET /result/assignment/{assignmentId}/group_formation
 *
 * @param assignmentId Assignment ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getGroupFormationResults = (
  assignmentId: string,
  successCb: (arg0: GroupFormationResult[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/result/assignment/${assignmentId}/group_formation`, successCb, errorCb);
};

/**
 * GET /result/assignment/{assignmentId}/group_formation/all
 *
 * @param assignmentId Assignment ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getAllGroupFormationResults = (
  assignmentId: string,
  successCb: (arg0: GroupWithGroupFormationResults[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/result/assignment/${assignmentId}/group_formation/all`, successCb, errorCb);
};

/**
 * POST /course/{courseId}/roster/TA
 *
 * @param {*} courseId Course ID
 * @param {*} reqData The request data to post
 * @param {*} successCb Callback for the resulting content ID
 * @param {*} errorCb Callback for error
 */
export const postAddTAs = (
  courseId: string,
  reqData: { firstName: string; lastName: string; email: string }[],
  successCb: (arg0: { course: Course } & CourseUser) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/course/${courseId}/roster/TA`, reqData, successCb, errorCb);
};

/**
 * POST /course/{courseId}/roster/lead
 *
 * @param {*} courseId Course ID
 * @param {*} reqData The request data to post
 * @param {*} successCb Callback for the resulting content ID
 * @param {*} errorCb Callback for error
 */
export const postAddLeadInstructors = (
  courseId: string,
  reqData: { firstName: string; lastName: string; email: string }[],
  successCb: (arg0: { course: Course } & CourseUser) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/course/${courseId}/roster/lead`, reqData, successCb, errorCb);
};

/**
 * POST /course/{courseId}/roster/graders
 *
 * @param {*} courseId Course ID
 * @param {*} reqData The request data to post
 * @param {*} successCb Callback for the resulting content ID
 * @param {*} errorCb Callback for error
 */
export const postAddGraders = (
  courseId: string,
  reqData: { firstName: string; lastName: string; email: string }[],
  successCb: (arg0: { course: Course } & CourseUser) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/course/${courseId}/roster/graders`, reqData, successCb, errorCb);
};

/**
 * POST /course/{courseId}/roster/external_reviewers
 *
 * @param {*} courseId Course ID
 * @param {*} reqData The request data to post
 * @param {*} successCb Callback for the resulting content ID
 * @param {*} errorCb Callback for error
 */
export const postAddExternalReviewers = (
  courseId: string,
  reqData: { firstName: string; lastName: string; email: string }[],
  successCb: (arg0: { course: Course } & CourseUser) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/course/${courseId}/roster/external_reviewers`, reqData, successCb, errorCb);
};

/**
 * PUT /course/{courseId}/user/{userId}/role/{role}
 *
 * @param courseId ID of the course
 * @param userId ID of the user
 * @param role the role is changed to
 * @param reqData Assignment data
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const updateCourseUserRole = (
  courseId: string,
  userId: string,
  role: string,
  successCb: (courseUser: CourseUser) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPut(`/course/${courseId}/user/${userId}/role/${role}`, {}, successCb, errorCb);
};

/**
 * GET /course/{courseId}/role/{role}/permissions
 *
 * @param courseId Course ID
 * @param role
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getRolePermissions = (
  courseId: string,
  role: string,
  successCb: (arg0: RosterPermission[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/course/${courseId}/role/${role}/permissions`, successCb, errorCb);
};

/**
 * PUT /course/{courseId}/role/{role}/permission/{permission}
 *
 * @param courseId ID of the course
 * @param role the permission role to update
 * @param permission the filed of permission to be updated
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const updateRosterPermission = (
  courseId: string,
  role: string,
  permission: string,
  successCb: (courseUser: RosterPermission[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPut(`/course/${courseId}/role/${role}/permission/${permission}`, {}, successCb, errorCb);
};

/**
 * GET /course/{courseId}/user/{userId}/accessPermission
 *
 * @param courseId Course ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getAccessPermission = (
  courseId: string,
  successCb: (arg0: AccessPermission) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/course/${courseId}/user/permissions`, successCb, errorCb);
};

/**
 * POST /course/{courseId}/section
 *
 * @param courseId Course ID
 * @param sectionName Name of section
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const createCourseSection = (
  courseId: string,
  sectionName: string,
  successCb: (arg0: CourseSection[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/course/${courseId}/section`, { courseId, sectionName }, successCb, errorCb);
};

/**
 * POST /course/{courseId}/sections/import
 *
 * @param courseId Course ID
 * @param sections Parsed CSV of course sections, as an array of string arrays
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const importSections = (
  courseId: string,
  groups: string[][],
  successCb: (arg0: CourseSection[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/course/${courseId}/sections/import`, { groups }, successCb, errorCb);
};

/**
 * GET /course/{courseId}/sections
 *
 * @param courseId Course ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const retrieveCourseSections = (
  courseId: string,
  successCb: (arg0: CourseSectionUser[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/course/${courseId}/sections`, successCb, errorCb);
};

/**
 * DELETE /course/{courseId}
 *
 * @param courseId Course ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const deleteAllCourseSections = (courseId: string, successCb: () => void, errorCb = defaultErrorCb): void => {
  requestDelete(`/course/${courseId}/sections`, successCb, errorCb);
};

/**
 * DELETE /course/{courseId}/section/{sectionId}
 *
 * @param courseId Course ID
 * @param sectionId Course Section ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const deleteCourseSection = (
  courseId: string,
  sectionId: string,
  successCb: () => void,
  errorCb = defaultErrorCb,
): void => {
  requestDelete(`/course/${courseId}/section/${sectionId}`, successCb, errorCb);
};

/**
 * DELETE /course/{courseId}/section/{sectionId}/{user}/{userId}
 *
 * @param courseId Course ID
 * @param sectionId Course Section ID
 * @param userId User ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const removeUserFromCourseSection = (
  courseId: string,
  sectionId: string,
  userId: string,
  successCb: () => void,
  errorCb = defaultErrorCb,
): void => {
  requestDelete(`/course/${courseId}/section/${sectionId}/user/${userId}`, successCb, errorCb);
};

/**
 * PUT /course/{courseId}/section/{sectionId}
 *
 * @param courseId Course ID
 * @param sectionId Section ID
 * @param reqData Section data
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const updateCourseSection = (
  courseId: string,
  sectionId: string,
  reqData: CourseSection,
  successCb: (arg0: User) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPut(`/course/${courseId}/section/${sectionId}`, reqData, successCb, errorCb);
};

/**
 * GET /course/{courseId}/unsectioned
 *
 * @param courseId Course ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getUnsectionedUsers = (
  courseId: string,
  successCb: (arg0: CourseUser[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/course/${courseId}/unsectioned`, successCb, errorCb);
};

/**
 * POST /course/{courseId}/section/{sectionId}/enroll
 *
 * @param courseId Course ID
 * @param sectionId Section ID of the section to add users to
 * @param userIds List of User IDs of users to add to group
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const addUsersToSection = (
  courseId: string,
  sectionId: string,
  userIds: string[],
  successCb: () => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/course/${courseId}/section/${sectionId}/enroll`, { userIds }, successCb, errorCb);
};

/**
 * GET /course/{courseId}/lines
 *
 * @param courseId Course ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getLineItemAssignmentPairs = (
  courseId: string,
  successCb: (arg0: AssignmentLineItemPair[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/course/${courseId}/lines`, successCb, errorCb);
};

/**
 * GET /course/{courseId}/assignment/{assignmentId}/lineItem
 *
 * @param assignmentId Course ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getLineItemForAssignment = (
  courseId: string,
  assignmentId: string,
  successCb: (arg0: LineItem) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/course/${courseId}/assignment/${assignmentId}/lineItem`, successCb, errorCb);
};

/**
 * POST /assignment/{assignmentId}/lti/send
 *
 * @param {*} assignmentId The assignment ID
 * @param {*} successCb Callback for success
 * @param {*} errorCb Callback for error
 */
export const forceResendLTIResult = (assignmentId: string, successCb: () => void, errorCb = defaultErrorCb): void => {
  requestPost(`/assignment/${assignmentId}/lti/send`, {}, successCb, errorCb);
};

/**
 * POST /assignment/{assignmentId}/user/{userId}/lti/send
 *
 * @param {*} assignmentId The assignment ID
 * @param {*} userId User ID
 * @param {*} successCb Callback for success
 * @param {*} errorCb Callback for error
 */
export const forceResendLTIResultForStudent = (
  assignmentId: string,
  userId: string,
  successCb: () => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/assignment/${assignmentId}/user/${userId}/lti/send`, {}, successCb, errorCb);
};

/**
 * GET /course/{courseId}/unconnected/lineItems
 *
 * @param assignmentId Course ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getUnconnectedLineItems = (
  courseId: string,
  successCb: (arg0: LineItem[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/course/${courseId}/unconnected/lineItems`, successCb, errorCb);
};

/**
 * POST /assignment/{assignmentId}/connect
 *
 * @param {*} assignmentId The assignment ID
 * @param {*} lineItemId lineItemId
 * @param {*} successCb Callback for success
 * @param {*} errorCb Callback for error
 */
export const connectAssignmentAndLineItem = (
  assignmentId: string,
  lineItemId: string,
  successCb: () => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/assignment/${assignmentId}/connect`, { lineItemId }, successCb, errorCb);
};

/**
 * POST /assignment/{assignmentId}/disconnect
 *
 * @param {*} assignmentId The assignment ID
 * @param {*} successCb Callback for success
 * @param {*} errorCb Callback for error
 */
export const disconnectAssignmentAndLineItem = (
  assignmentId: string,
  successCb: () => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/assignment/${assignmentId}/disconnect`, {}, successCb, errorCb);
};

/**
 * POST /course/{courseId}/lineItems/delete
 *
 * @param {*} courseId The assignment ID
 * @param {*} successCb Callback for success
 * @param {*} errorCb Callback for error
 */
export const deleteAllLineItems = (courseId: string, successCb: () => void, errorCb = defaultErrorCb): void => {
  requestPost(`/course/${courseId}/lineItems/delete`, {}, successCb, errorCb);
};

/**
 * POST /assignment/{assignmentId}/lineitem
 *
 * @param {*} assignmentId The assignment ID
 * @param {*} successCb Callback for success
 * @param {*} errorCb Callback for error
 */
export const newLineItems = (assignmentId: string, successCb: () => void, errorCb = defaultErrorCb): void => {
  requestPost(`/assignment/${assignmentId}/lineitem`, {}, successCb, errorCb);
};

/**
 * PUT /assignment/{assignmentId}/maxScore
 *
 * @param {*} assignmentId The assignment ID
 * @param {*} successCb Callback for success
 * @param {*} errorCb Callback for error
 */
export const editLineItemMaxScore = (
  assignmentId: string,
  reqData: LineItem,
  successCb: () => void,
  errorCb = defaultErrorCb,
): void => {
  requestPut(`/assignment/${assignmentId}/maxScore`, reqData, successCb, errorCb);
};

/**
 * GET /domain
 *
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getApprovedDomains = (successCb: (arg0: ApprovedDomain[]) => void, errorCb = defaultErrorCb): void => {
  requestGet(`/domains`, successCb, errorCb);
};

/**
 * GET /domain/{domainId}
 *
 * @param domainId
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getApprovedDomain = (
  domainId: string,
  successCb: (arg0: ApprovedDomain) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/domain/${domainId}`, successCb, errorCb);
};

/**
 * POST /domains
 *
 * @param domain the domain list to be added to the database
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const addApprovedDomain = (
  reqData: { domainName: string; seatsCap: string }[],
  successCb: (arg0: ApprovedDomain[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/domains`, reqData, successCb, errorCb);
};

/**
 * DELETE /domain/{domainId}
 *
 * @param domainId Review ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const deleteApprovedDomain = (
  domainId: string,
  successCb: (arg0: ApprovedDomain[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestDelete(`/domain/${domainId}`, successCb, errorCb);
};

/**
 * PUT /domain/{domainId}
 *
 * @param {*} domainId assignmentId to post
 * @param {*} successCb Callback for successful POST
 * @param {*} errorCb Callback for error
 */
export const changeDomainMode = (domainId: string, successCb: () => void, errorCb = defaultErrorCb): void => {
  requestPut(`/domain/${domainId}`, {}, successCb, errorCb);
};

/**
 * PUT /domain/{domainId}/seats/{seats}
 *
 * @param {*} domainId assignmentId to post
 * @param {*} seats the number of seats
 * @param {*} successCb Callback for successful POST
 * @param {*} errorCb Callback for error
 */
export const updateSeatCap = (
  domainId: string,
  seats: string,
  successCb: () => void,
  errorCb = defaultErrorCb,
): void => {
  requestPut(`/domain/${domainId}/seats/${seats}`, {}, successCb, errorCb);
};

/**
 * GET /contracts
 *
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getContracts = (successCb: (arg0: Contract[]) => void, errorCb = defaultErrorCb): void => {
  requestGet(`/contracts`, successCb, errorCb);
};

/**
 * POST /user/contract
 *
 * @param email Email of user to create contract for
 * @param contract Contract to POST
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const createContract = (
  email: string,
  contract: POST_Contract,
  successCb: () => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/contract`, { email, contract }, successCb, errorCb);
};

/**
 * PUT /contract/{contractId}/edit
 *
 * @param contractId contractId
 * @param email Email of user to create contract for
 * @param contract Contract to POST
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const editContract = (
  contractId: string,
  email: string,
  contract: POST_Contract,
  successCb: () => void,
  errorCb = defaultErrorCb,
): void => {
  requestPut(`/contract/${contractId}/edit`, { email, contract }, successCb, errorCb);
};

/**
 * DELETE /contract/{contractId}
 *
 * @param contractId Contract ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const deleteContract = (
  contractId: string,
  successCb: (arg0: Contract[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestDelete(`/contract/${contractId}`, successCb, errorCb);
};

/**
 * GET /contract/user/{userId}
 *
 * @param userId UserId
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getContractForUser = (successCb: (arg0: Contract[]) => void, errorCb = defaultErrorCb): void => {
  requestGet(`/contract/user`, successCb, errorCb);
};

/**
 * GET /contract/{contractId}/seats
 *
 * @param contractId contractId
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getSeatConsumption = (
  contractId: string,
  successCb: (arg0: SeatConsumption[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/contract/${contractId}/seats`, successCb, errorCb);
};

/**
 * GET /domain/{domainName}/seats
 *
 * @param domainName domain Name
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getApprovedDomainSeatConsumption = (
  domainName: string,
  successCb: (arg0: SeatConsumption[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/domain/${domainName}/seats`, successCb, errorCb);
};

/**
 * GET /contract/{contractId}
 *
 * @param userId UserId
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getContract = (
  contractId: string,
  successCb: (arg0: Contract) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/contract/${contractId}`, successCb, errorCb);
};

/**
 * POST /course/{courseId}/user/{userId}/paid
 *
 * @param courseId Course ID
 * @param userId User ID
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const instructorMarkPaid = (
  courseId: string,
  userId: string,
  successCb: () => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/course/${courseId}/user/${userId}/paid`, {}, successCb, errorCb);
};

/**
 * POST /contract/{contractId}/share
 *
 * @param {*} contractId Course ID
 * @param {*} reqData The request data to post
 * @param {*} successCb Callback for the resulting content ID
 * @param {*} errorCb Callback for error
 */
export const postShareContractWithUsers = (
  contractId: string,
  reqData: { email: string }[],
  successCb: (users: User[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/contract/${contractId}/share`, reqData, successCb, errorCb);
};

/**
 * POST /contract/{contractId}/share
 *
 * @param {*} contractId Course ID
 * @param {*} email The shared email to delete
 * @param {*} successCb Callback for the resulting content ID
 * @param {*} errorCb Callback for error
 */
export const deleteSharedContractUser = (
  contractId: string,
  email: string,
  successCb: (users: User[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestDelete(`/contract/${contractId}/share?email=${email}`, successCb, errorCb);
};

/**
 * GET /contract/{contractId}/shared
 *
 * @param {*} contractId Course ID
 * @param {*} successCb Callback for the resulting content ID
 * @param {*} errorCb Callback for error
 */
export const getSharedContractUsers = (
  contractId: string,
  successCb: (users: User[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/contract/${contractId}/shared`, successCb, errorCb);
};

/**
 * GET /user/{userId}/sharelist
 *
 * @param userId UserId
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const getPotentialCoursesToShareSeatsWith = (
  userId: string,
  successCb: (arg0: Course[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestGet(`/user/${userId}/sharelist`, successCb, errorCb);
};

/**
 * POST /course/{courseId}/shareseats
 *
 * @param courseId CourseId
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const shareSeatWithCourse = (
  courseId: string,
  successCb: (arg0: Course[]) => void,
  errorCb = defaultErrorCb,
): void => {
  requestPost(`/course/${courseId}/shareseats`, {}, successCb, errorCb);
};

/**
 * POST /course/{courseId}/sync
 *
 * @param courseId CourseId
 * @param successCb Callback for success
 * @param errorCb Callback for error
 */
export const syncRoster = (courseId: string, successCb: () => void, errorCb = defaultErrorCb): void => {
  requestPost(`/course/${courseId}/sync`, {}, successCb, errorCb);
};
